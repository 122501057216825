import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import i18n from './plugins/i18n.js'
Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    // Round to nearest tens of a cent and display two decimal values after comma
    formatCurrencyTotal(currency) {
      var dvalue = Math.round(currency * 10) / 10;
      return `${dvalue.toFixed(2)}`;
    },
    formatMoney(number) {
      // Create our number formatter.
      var formatter = new Intl.NumberFormat("no-NB", {
        style: "currency",
        currency: "NOK",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(number); /* $2,500.00 */
    },
    // round to nearest cent and display two decimal values after comma
    formatCurrency(currency) {
      var dvalue = Math.round(currency * 100) / 100;
      return `${dvalue.toFixed(2)}`;
    },

    formatWithoutCurrency(currency) {
      var dvalue = Math.round(currency * 100) / 100;
      return `${dvalue.toFixed(2)}`;
    },

    SameValue(val1, val2) {
      const strA = JSON.stringify(val1);
      const strB = JSON.stringify(val2);
      var same = (strA === strB)

      return same
    },

    serializeToQueryParams(obj) {
      for (let prop in obj) {
        if (obj[prop] === undefined || obj[prop] === null || obj[prop] === "") {
          delete obj[prop];
        }
      }
      var queryparams = '?' + new URLSearchParams(obj).toString();
      //console.log(queryparams);
      return queryparams;
    },

    setFavicon(file) {
      file = file ?? 'favicon.ico';
      const favicon = document.getElementById("favicon");
      favicon.href = "" + window.location.origin + "/" + file;
      console.log("set favicon ", favicon.href);
    },

    getQueryParam(querykey, fallback) {
      var value = this.$route.query[querykey];
      if (value) {
        value = JSON.parse(value);
        return value;
      }
      else { return fallback; }
    },
    setQueryParam(querykey, update) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          [querykey]: this.validateUpdate(update)
            ? JSON.stringify(update)
            : undefined,
        },
      });
    },
    validateUpdate(update) {
      return update &&
        (!Array.isArray(update) ||
          (Array.isArray(update) && update.some(x => x !== undefined && x !== null)));
    },

    statuscolor(statusid) {
      switch (statusid) {
        //unplanned:
        case 5:
          return "jobstatus_forecast_color";
        case 10:
          return "jobstatus_new_color";

        //being worked on:
        case 20:
          return "jobstatus_planned_color";
        case 25:
          return "jobstatus_drive_color";
        case 30:
          return "jobstatus_in_progress_color";
        case 40:
        case 45:
          return "jobstatus_waiting_color";

        //finished:
        case 50:
          return "jobstatus_completed_color";
        case 60:
          return "jobstatus_warranty_color";
        case 70:
          return "jobstatus_approved_color";

        //deleted
        case 100:
          return "jobstatus_closed_color";
        case 110:
          return "error";

        //other:
        default:
          return "disabled";
      }
    },

    machineGeneralizerText(machine) {
      var text = '';
      if (machine) {
        var model = machine.MachineModel;
        if (model) {
          if (model.MachineType) {
            text = model.MachineType.Name + ' ';
          }
          text = text + model.Name;
        }
        else {
          text = text + ([machine.MachineType, machine.MachineModelName].join(' '));
        }
      }
      return text;
    },

    machineIdentifierText(machine) {
      var text = '';
      if (machine) {
        text = text + machine.Name;
        if (machine.SerialNumber || machine.Qrcode) {
          text = text + ' [';
          if (machine.SerialNumber) {
            text = text + machine.SerialNumber;
          }
          if (machine.SerialNumber && machine.Qrcode) {
            text = text + ', ';
          }
          if (machine.Qrcode) {
            text = text + machine.Qrcode;
          }
          text = text + ']';
        }
      }
      return text;
    },
  },

  computed: {
    //returns true if device is wider than 1904px
    isUltrawideBool() {
      return this.$vuetify.breakpoint.xlOnly;
    },

    //returns true if device is wider than 1264px
    isPcBool() {
      return this.$vuetify.breakpoint.lgAndUp;
    },

    //returns true if device is thinner than 1264px
    isPadBool() {
      return this.$vuetify.breakpoint.mdAndDown;
    },

    //returns true if device is thinner than 600px (vertical phone screens)
    isPhoneBool() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  }
});

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
