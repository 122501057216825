<template>
  <loading-box v-if="!Part" :modal="true" />
  <v-dialog v-model="value" max-width="1000" v-else @click:outside="hide">
    <v-card v-if="Part">
      <v-card-title>
        {{ Part.ArticleNumber }}
        <v-spacer />
      </v-card-title>

      <v-row class="ma-0 pa-0">
        <v-col class="text-left">
          <v-card-subtitle class="ma-0 pa-0">
            {{ $t("text.description") }}
          </v-card-subtitle>
          <v-divider />
          <v-card-subtitle class="ma-0 pa-0">
            {{ Part.ArticleDescription }}
          </v-card-subtitle>
          <v-card-subtitle>
            {{ Part.ArticleDescriptionLong }}
          </v-card-subtitle>
          <v-card-text>
            {{ $t("text.price") }}
            <h3>
              {{ Part.ArticlePrice }} {{ $store.state.userInfo.Currency }}
            </h3>
          </v-card-text>
          <!-- <v-card-text>Lagerstatus: På lager</v-card-text> -->
        </v-col>
        <v-col>
          <ArticleImage :size="200" :relativePath="Part && Part.ArticleImage">
          </ArticleImage>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-btn @click="$router.back()" outlined color="accent darken-2">
          {{ $t("text.back") }}
        </v-btn>
        <v-spacer />
        <v-text-field v-model="quantity" type="number" outlined :label="$t('text.amount')" class="shrink mr-5"
          hide-details dense style="width: 75px">
        </v-text-field>
        <v-btn color="primary" @click="addToCart()" :disabled="isInAsync">
          <v-icon>mdi-cart</v-icon>+
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import http from "@/api/client";
import LoadingBox from "@/components/LoadingBox";
import ArticleImage from "@/components/Cards/ArticleImage.vue";

export default {
  name: "PartInfoDialog",
  components: {
    "loading-box": LoadingBox,
    ArticleImage,
  },

  data() {
    return {
      value: true,
      Part: null,
      quantity: 1,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.part = this.$route.params.part;
      if (this.part) {
        http()
          .get("api2/customer/article/part/" + this.part)
          .then((response) => {
            var partItem = response.data;
            this.Part = partItem;
          });
      }
    },

    addToCart() {
      const machineId = this.$route.params.id;
      this.Part.MachineId = machineId;
      this.Part.Quantity = this.quantity;
      this.$store.dispatch("addCart", this.Part);
    },

    hide() {
      this.$router.back()
    },
  },

  computed: {
    isInAsync() {
      return this.$store.state.shoppingCartLoading;
    },
  },

  watch: {
    $route() {
      this.init();
    },
  },
};
</script>