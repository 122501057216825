<template>
  <v-list-item class="pl-0" :class="isPhoneBool ? 'pr-2' : ''" style="border-bottom: 1px solid #00000014;"
    @click="$emit('click')">
    <v-list-item-action class="ma-0 mr-3">
      <ArticleImage :size="isPhoneBool ? 42 : 62" :relativePath="item && item.ArticleImage">
      </ArticleImage>
    </v-list-item-action>

    <v-list-item-content :class="isPhoneBool ? 'py-0' : ''">
      <v-list-item-title> {{ item.ArticleDescription }} </v-list-item-title>
      <v-list-item-subtitle> {{ item.ArticleNumber }}</v-list-item-subtitle>
    </v-list-item-content>

    <!-- price field hidden on phone sized devices -->
    <v-list-item-action v-if="!isPhoneBool" class="pr-3 my-0">
      <v-list-item-subtitle>
        <b v-if="!loadingPrice">{{ priceText(item) }}</b>
        <v-progress-circular v-else indeterminate color="primary" style="opacity: 0.5;" size="20" width="1">
        </v-progress-circular>
      </v-list-item-subtitle>
    </v-list-item-action>

    <!-- amount field hidden on phone sized devices -->
    <v-list-item-action v-if="!isPhoneBool" class="my-0" @click.stop="() => false">
      <v-text-field style="width: 60px" v-model="item.Quantity" hide-details outlined dense type="number"
        :label="$t('text.amount')" @click.stop="() => false" class="shrink">
      </v-text-field>
    </v-list-item-action>

    <v-list-item-action class="my-0">
      <v-btn color="primary" @click.stop="$emit('addToCart')"
        :style="isPhoneBool ? 'min-width: unset; padding: 0 6px;' : ''">
        <v-icon>mdi-cart</v-icon>+
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>


<script>
import http from "@/api/client";
import ArticleImage from "@/components/Cards/ArticleImage.vue";

export default {
  name: "CommonPartListItem",
  props: {
    item: Object,
    machineId: Number,
  },
  components: {
    ArticleImage,
  },

  data() {
    return {
      loadingPrice: false, //here instead of on item to ensure reactivity
    };
  },

  mounted() { },

  methods: {
    priceText(item) {
      var price = this.getPrice(item);
      if (price == null || price == "" || price == 0) {
        return "";
      }
      else {
        return this.formatCurrency(price);
      }
    },

    getPrice(item) {
      if (item.CustomerArticlePrice == null) {
        item.CustomerArticlePrice = "";
        this.loadingPrice = true;
        var partUrl = "api2/customer/machines/Price/" + this.machineId + "?articleNumber=" + encodeURIComponent(item.ArticleNumber);
        http()
          .get(partUrl)
          .then((articlePrice) => {
            item.CustomerArticlePrice = articlePrice.data;
            item.HasCustomerPrice = true;
          }).catch(() => {
            item.HasCustomerPrice = false;
          }).finally(() => {
            this.loadingPrice = false;
          })
      }
      return item.CustomerArticlePrice;
    },
  },

  computed: {},

  watch: {},
};
</script>

<style scoped></style>