<template>
  <v-card>
    <v-row no-gutters class="px-4 pt-3">
      <v-col cols="0">
        <h4 v-text="$t('text.users')" />
      </v-col>
      <v-col cols="auto">
        <v-checkbox v-model="showInactive" label="Show deactivated" class="ma-0 pa-0" dense hide-details>
        </v-checkbox>
      </v-col>
    </v-row>
    <!--
          <div class="px-4 py-2">
            Accounts are used for logging into this portal.
            The list bellow shows the accounts that are connected to this organization.
            You can create an account from a contact by clicking the "Create account" button on the contact menu and
            visa
            versa.
          </div>
          -->
    <v-list class="py-0">
      <UserListItem :header="true"></UserListItem>
      <v-progress-linear v-if="loading" absolute indeterminate height="2"></v-progress-linear>
      <UserListItem v-for="item in filteredAccounts" :key="item.Id" :item="item" :me="userInfo">
      </UserListItem>
    </v-list>

    <confirm-dialog ref="ConfirmDialog"></confirm-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      Account deactivated
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="unToggleActive()">
          {{ $t("text.undo") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>


<script>
import http from "@/api/client";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";
import UserListItem from "@/components/ListItems/UserListItem.vue";
import Vue from "vue";

export default {
  name: "AccountList",
  components: {
    ConfirmDialog,
    UserListItem,
  },

  data() {
    return {
      hintExpanded: false,

      loading: false,
      accounts: [],
      showInactive: false,

      lastToggledId: null,
      snackbar: false,
      timeout: 5000,
    };
  },

  created() {
    this.getAccounts();
  },

  methods: {
    async getAccounts() {
      this.loading = true;
      await http()
        .get("api2/Customer/User/SearchCustomerUsers")
        .then((response) => {
          this.accounts = response.data;
        });
      this.loading = false;
    },

    async unToggleActive() {
      await this.toggleActiveCall(this.lastToggledId);
      var target = this.organization.CustomerPeople.find((x) => x.Id === this.lastToggledId);
      Vue.set(target, "IsActive", !target.IsActive);
      this.snackbar = false;
    },

    async toggleActiveCall(id) {
      var result = await http().patch("api2/customer/userinfo/Contact/ToggleActive/" + id);
      return result;
    },

    async toggleActive(item) {
      var result = true;
      //show confirm dialog if deactivating
      if (!item.IsActive) {
        result = await this.$refs.ConfirmDialog.show({
          title: "Deactivate " + item.Name,
          message: "Are you sure?",
          okButton: "Deactivate",
          cancelButton: "Cancel",
        });
      }
      //undo change if cancel
      if (!result) {
        Vue.set(item, "IsActive", !item.IsActive);
        return;
      }
      else {
        this.lastToggledId = item.Id;
        await this.toggleActiveCall(item.Id);
        this.snackbar = true;
      }
    },
  },

  computed: {
    filteredAccounts() {
      return this.accounts.filter((x) => (this.showInactive ? true : x.Active));
    },

    userInfo() {
      return this.$store.state.userInfo;
    }
  },

  watch: {},
};
</script>

<style>
.expanded {}

.collapsed {
  max-height: 90px;
  overflow-y: hidden;
}
</style>