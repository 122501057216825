<template>
  <v-container fluid class="pa-0">
    <v-card :loading="true" v-if="machine == null">
      <v-card-title>{{ $t("text.loading") }}</v-card-title>
    </v-card>
    <v-card v-else :loading="loading" flat>
      <v-list class="py-0">
        <v-list-item v-for="item in documents" :key="item.Name" @click="showItem(item)" dense
          style="border-top: 1px solid #dddddd;">
          <v-list-item-avatar>
            <v-img :src="item.PartDrawingImage" max-height="250" max-width="250" contain />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.Name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>


<script>
import http from "@/api/client";

export default {
  name: "CustomerMachinePartsDocumentPanel",

  data() {
    return {
      documents: null,
      selectedDoc: null,
      loading: false,
      machine: this.$route.params.Machine,
    };
  },

  mounted() {
    this.init();
    if (!this.machine) this.$emit("updateMachine");
  },

  methods: {
    showItem(item) {
      this.$router.push({
        name: "CustomerPartDocumentDialog",
        params: {
          documentid: item.ModelDocuments[0].DocumentId,
          modelid: item.ModelDocuments[0].MachineModelId,
        },
      });
    },

    init() {
      if (this.$route.params.Machine) {
        this.machine = this.$route.params.Machine;
        this.updateDocuments(this.machine);
      }
    },

    updateDocuments(machine) {
      if (machine == null) return;
      this.loading = true;
      http()
        .get("api2/customer/documents/partdrawings/" + machine.Id)
        .then((response) => {
          this.documents = response.data;
          this.loading = false;
        });
    },
  },

  computed: {
    /*
    customerDocs() {
      if (this.documents.CustomerDocs) {
        return this.documents.CustomerDocs;
      }
      return [];
    },
    techDocuments() {
      if (this.documents.TechnicalDocs) {
        return this.documents.TechnicalDocs;
      }
      return [];
    },
    */
  },

  watch: {
    $route() {
      this.init();
    },

    machine(val) {
      this.updateDocuments(val);
    },
  },
};
</script>

<style scoped>
.highlighted {
  background-color: var(--v-secondary-base) !important;
}

area {
  border: 1px solid red;
  background-color: red;
}
</style>