<template>
  <v-card :flat="flat"
          :color="transparent ? 'transparent' : ''"
          :dark="dark">
    <h5 v-text="$t('text.your company')" />
    <br>
    {{$t('text.name')}}: {{userInfo.Customer.Name}} <br>
    <!--
    <v-icon size="18"
            class="mr-1">mdi-email</v-icon> {{userInfo.Customer.Email}} <br>
    <v-icon size="18"
            class="mr-1">mdi-phone</v-icon> {{userInfo.Customer.Telephone}} <br>
    -->
  </v-card>
</template>


<script>
export default {
  name: "CompanyContactCard",
  props: {
    dark: Boolean,
    transparent: Boolean,
    flat: Boolean,
  },
  components: {},

  data() {
    return {};
  },

  methods: {},

  computed: {
    userInfo() {
      if (this.$store.state.userInfo) return this.$store.state.userInfo;
      return { Customer: {}, Provider: {} };
    },
  },

  watch: {},
};
</script>


<style lang='scss' scoped>
</style>