<template>
  <v-card outlined :class="isPhoneBool ? 'mx-2' : 'mx-3'" class=" mb-2">
    <v-row no-gutters align="center">
      <v-col :cols="isPhoneBool ? 12 : 6" :class="isPhoneBool ? 'pa-2 pb-1' : ''" class="d-flex align-center">
        <v-col cols="auto" class="pa-0">
          <ArticleImage :size="55" :relativePath="line.Article && line.Article.ArticleImage">
          </ArticleImage>
        </v-col>
        <v-col cols="0" class="pa-0 pl-2">
          <v-row no-gutters style="font-size: 14px;">
            {{ line.Article.ArticleDescription }}
          </v-row>
          <v-row no-gutters style="opacity: 0.7;">
            <b>{{ line.Article.ArticleNumber }}</b>
          </v-row>
        </v-col>
      </v-col>
      <v-col :cols="isPhoneBool ? 12 : 6" :class="isPhoneBool ? 'pl-3 pb-2' : ''" class="d-flex align-center">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-0 pr-2" style="font-size: 15px;">
          <b>{{ line.ArticlePrice ? formatCurrency(line.ArticlePrice) : '' }}</b>
        </v-col>
        <v-card class="ml-4 mr-4 d-flex align-center" flat>
          <v-btn class="rounded-pill px-1" outlined :color="line.ArticleCount > 1 ? 'primary' : 'disabled'" x-small
            style="min-width: unset;" :disabled="line.ArticleCount <= 1" @click="incrementCartItem(line, -1)">
            <v-icon size="10">mdi-minus</v-icon>
          </v-btn>
          <div class="px-2" style="font-size: 15px">{{ line.ArticleCount }}</div>
          <v-btn class="rounded-pill px-1" color="primary" x-small style="min-width: unset;"
            @click="incrementCartItem(line, 1)">
            <v-icon size="12">mdi-plus</v-icon>
          </v-btn>
        </v-card>
        <v-btn icon @click.stop="deleteItem(line)" class="pl-1 pr-1">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import http from "@/api/client";
import ArticleImage from "@/components/Cards/ArticleImage.vue";

export default {
  name: "CartLineListItem",
  props: {
    line: Object,
  },
  components: {
    ArticleImage,
  },

  data() {
    return {
    };
  },

  mounted() { },

  methods: {
    incrementCartItem(item, increment) {
      item.ArticleCount += increment;
      this.updateItemCount(item);
    },

    updateItemCount(item) {
      http()
        .patch("api2/customer/shoppingcart/detail/" + item.Id + "/count/" + item.ArticleCount, item)
        .then(() => {
          //this.updateCart();
          this.sendUpdateCartEvent();
        });
    },


    deleteItem(item) {
      http()
        .delete("api2/customer/shoppingcart/detail/" + item.Id, item)
        .then(() => {
          this.updateCart();
          this.sendUpdateCartEvent();
        });
    },

    updateCart() {
      this.$store.dispatch("silentGetShoppingCart");
    },

    //notify flutter webview to update if available
    sendUpdateCartEvent() {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler("updateCartEvent");
      }
    },
  },

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped></style>
