<template>
  <v-container fluid class="pa-0">
    <v-card :loading="true" v-if="machine == null">
      <v-card-title>{{ $t("text.loading") }}</v-card-title>
    </v-card>
    <v-card v-else :loading="loading" flat>
      <v-row no-gutters>
        <v-col cols="12">
          <v-list class="py-0">
            <v-list-item v-for="item in documents" :key="item.Id" @click="showItem(item)"
              style="border-top: 1px solid #dddddd;">
              <v-list-item-icon class="my-auto mr-3">
                <v-icon>
                  {{ getDocumentTypeIcon(item.DocumentType_Id) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.Name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>


<script>
import http from "@/api/client";

export default {
  name: "CustomerMachineManualsPanel",

  data() {
    return {
      documents: null,
      loading: false,
      machine: this.$route.params.Machine,
    };
  },

  mounted() {
    this.init();
    if (this.machine) this.updateManuals(this.machine);
    else this.$emit("updateMachine");
  },

  methods: {
    init() {
      this.machine = this.$route.params.Machine;
    },
    showItem(item) {
      window.open(item.LinkUrl, "_blank");
    },
    getDocumentTypeIcon(documentTypeId) {
      switch (documentTypeId) {
        case 1:
          return "mdi-file-pdf";
        default:
          return "mdi-file";
      }
    },
    updateManuals(machine) {
      if (machine) {
        this.loading = true;
        http()
          .get("api2/customer/documents/manuals/" + machine.Id)
          .then((response) => {
            this.documents = response.data;
            this.loading = false;
          });
      }
    },
  },

  computed: {},

  watch: {
    $route() {
      this.init();
    },
    machine(val) {
      this.updateManuals(val);
    },
  },
};
</script>


<style scoped>
.highlighted {
  background-color: var(--v-secondary-base) !important;
}
</style>