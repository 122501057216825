<template>
    <v-card :style="cstyle" class="text-center py-3 my-3 tertiary--text" style="font-size: 16px;" :flat="!filled"
        width="100%" :disabled="disabled" @click.stop="handleClick()">
        {{ loading ? '' : text }}
        <v-progress-circular v-if="loading" indeterminate :style="'color: ' + fgc + 'important;'" width="2" size="20">
        </v-progress-circular>
    </v-card>
</template>


<script>
export default {
    name: 'WideBtn',
    props: {
        color: {
            type: String,
            default: 'var(--v-primary-base)',
        },
        filled: {
            type: Boolean,
            default: true,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: 'Button text',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {},

    data() {
        return {};
    },

    methods: {
        handleClick() {
            if (!this.disabled && !this.loading) {
                this.$emit('click');
            }
        },
    },

    computed: {
        c() { return this.disabled ? 'var(--v-disabled-base)' : this.color },
        fgc() { return !this.outlined && this.filled ? 'white' : this.c; },
        bgc() { return !this.outlined && this.filled ? this.c : '#fcfcfc'; },
        outline() { return this.outlined ? 'border: 2px solid ' + this.fgc + '!important;' : ''; },
        cstyle() {
            return 'background-color:' + this.bgc + '!important;' +
                'color:' + this.fgc + '!important;'
                + this.outline;
        },
    },

    watch: {},
};
</script>


<style lang='scss' scoped></style>