<template>
    <v-card flat @click="$emit('toggle', !collapsed)">
        <v-container class="pa-0 px-2">
            <v-row no-gutters align="center">
                <v-col cols="auto">
                    <h6 v-text="text" class="mx-2" />
                </v-col>
                <v-col cols="0" style="opacity: 0.3;">
                    <v-divider />
                </v-col>
                <v-col v-if="count" cols="auto" class="pl-3" style="font-size: 12px;">
                    {{ count }} files
                </v-col>
                <!-- 
            <v-col cols="auto">
                <v-btn icon small class="ml-1">
                    <v-icon v-text="collapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                        :color="collapsed ? 'tertiary' : ''">
                    </v-icon>
                </v-btn>
            </v-col>
            -->
            </v-row>
        </v-container>
    </v-card>
</template>


<script>
export default {
    name: 'FileSectionHeader',
    model: {
        prop: "collapsed",
        event: "toggle",
    },
    props: {
        text: String,
        count: Number,
        collapsed: Boolean,
        locked: {
            type: Boolean,
            default: true,
        },
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>