<template>
    <v-text-field :value="value" :label="$t('text.email')" :rules="emailRules" required outlined
        :loading="validationLoading" dense class="my-3" @input="e => $emit('input', e)">
    </v-text-field>
</template>


<script>
export default {
    name: 'EmailField',
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        value: String,
        loading: Boolean,

    },
    components: {},

    data() {
        return {
            validationLoading: false,
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    "E-mail must be valid",
            ],
        };
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>