const routes = {
  path: "/qr/:qr",
  name: "Qr",
  component: () => import("@/views/public/PublicQRView.vue"),
  children: [
    {
      name: "PublicHistory",
      path: "history",
      component: () => import("@/views/public/PublicMachineJobList.vue"),
    },
    {
      name: "PublicResources",
      path: "resources",
      component: () => import("@/views/public/PublicResourceList.vue"),
    },
  ],
};
export default routes;
