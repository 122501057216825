<template>
  <v-card min-height="calc(100vh - 150px)">
    <FolderList :resources="resources" :loading="loading" :wide="!isPhoneBool">
    </FolderList>
  </v-card>
</template>


<script>
import http from "@/api/client";
import FolderList from "@/components/FolderList.vue";

export default {
  name: "ResourceList",
  components: {
    FolderList,
  },

  data() {
    return {
      resources: null,
      loading: false,
      machine: this.$route.params.Machine,
    };
  },

  mounted() {
    this.init();
    if (this.machine) this.updateManuals(this.machine);
    else this.$emit("updateMachine");
  },

  methods: {
    init() {
      this.machine = this.$route.params.Machine;
    },
    showItem(item) {
      window.open(item.LinkUrl, "_blank");
    },
    getDocumentTypeIcon(documentTypeId) {
      switch (documentTypeId) {
        case 1:
          return "mdi-file-pdf";
        default:
          return "mdi-file";
      }
    },
    updateManuals(machine) {
      if (machine) {
        this.loading = true;
        http()
          .get("api2/customer/documents/resources/" + machine.Id)
          .then((response) => {
            this.resources = response.data;
            this.loading = false;
          });
      }
    },
  },

  computed: {},

  watch: {
    $route() {
      this.init();
    },
    machine(val) {
      this.updateManuals(val);
    },
  },
};
</script>


<style scoped></style>