<template>
  <div>
    <v-form v-model="valid" ref="form">

      <v-text-field v-model="email" ref="emailfield" :label="$t('text.email')" :rules="emailRules" required outlined
        dense>
      </v-text-field>
      <v-text-field v-model="password" ref="passwordfield" :label="$t('text.password')" dense min="8"
        :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (e1 = !e1)" :type="e1 ? 'password' : 'text'"
        id="pw" :rules="passwordRules" counter outlined required @keydown.enter="submit">
      </v-text-field>

      <WideBtn :text="$t('customer.portal.loginbutton')" color="var(--v-tertiary-base)" :disabled="!valid"
        :loading="loginInProgress" @click="submit()">
      </WideBtn>
      <WideBtn :text="$t('customer.portal.forgot_password')" color="var(--v-tertiary-base)" :disabled="loginInProgress"
        :filled="false" @click="mode = 'send'">
      </WideBtn>

      <v-snackbar v-model="snackbar" color="error">{{ snackbarText }}</v-snackbar>
    </v-form>
  </div>
</template>


<script>
import WideBtn from '@/components/WideBtn.vue';

export default {
  name: "LoginContent",
  components: {
    WideBtn,
  },

  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loginInProgress: false,
      valid: false,
      e1: true,
      password: "",
      passwordRules: [(v) => !!v || "Password is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },

  mounted() { },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loginInProgress = true;
        // call login
        const inputUserName = this.$refs.emailfield.value;
        const inputPassword = this.$refs.passwordfield.value;
        this.$store
          .dispatch("customerLogin", {
            username: inputUserName,
            password: inputPassword,
          })
          .then((result) => {
            if (!result.success) {
              this.snackbarText = result.errorMessage;
              this.snackbar = true;
            }
            this.loginInProgress = false;
          });
      }
    },
  },

  computed: {
    email: {
      get() {
        return this.getQueryParam("email", "");
      },
      set(update) {
        this.setQueryParam("email", update);
      },
    },

    mode: {
      get() {
        return this.getQueryParam("mode", "");
      },
      set(update) {
        this.setQueryParam("mode", update);
      },
    },
  },

  watch: {},
};
</script>


<style lang="scss"></style>