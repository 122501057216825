<template>
  <ContactListItemSlotTemplate :header="header" :class="!header && !item.IsActive ? 'deactivated' : ''"
    @click="e => $emit('click', e)">

    <template v-slot:avatar>
      <template v-if="header">
        <v-card width="25" class="mr-2"></v-card>
      </template>
      <v-icon v-else class="mr-2">mdi-account</v-icon>
    </template>

    <template v-slot:name>
      {{ header ? 'Name' : item.Name }}
    </template>

    <template v-slot:title>
      {{ header ? 'Title' : item.Title }}
    </template>


    <template v-slot:email>
      <template v-if="header">Email</template>
      <v-card v-else-if="item.Email" :class="emailTextColor + '--text'" flat color="transparent">
        <v-icon size="17" class="mr-1" :color="emailTextColor"
          v-text="item.EmailAllowed ? 'mdi-bell-ring' : 'mdi-bell-off'"></v-icon>
        {{ item.Email }}
      </v-card>
    </template>

    <template v-slot:mobile>
      <template v-if="header">Mobile</template>
      <div v-else-if="item.Mobile" :class="smsTextColor + '--text'">
        <v-icon size="17" class="mr-1" :color="smsTextColor"
          v-text="item.SmsAllowed ? 'mdi-bell-ring' : 'mdi-bell-off'"></v-icon>
        {{ item.Mobile }}
      </div>
    </template>

    <template v-slot:actions>
      <template v-if="header">
        <v-card flat class="ml-2">
          Active
        </v-card>
      </template>
      <div v-else icon class="ml-2">
        <v-switch v-model="item.IsActive" class="pa-0 ma-0" hide-details dense @change="$emit('toggleActive', item)">
        </v-switch>
      </div>
    </template>

  </ContactListItemSlotTemplate>
</template>


<script>
import ContactListItemSlotTemplate from '@/components/ListItems/ContactListItemSlotTemplate.vue';

export default {
  name: "ContactListItem",
  props: {
    header: Boolean,
    item: Object,
  },
  components: {
    ContactListItemSlotTemplate,
  },

  data() {
    return {
    };
  },

  mounted() { },

  methods: {},

  computed: {
    emailTextColor() {
      if (this.item.Email && this.item.EmailAllowed) {
        return this.item.IsActive ? 'tertiary' : 'black';
      }
      else {
        return '';
      }
    },
    smsTextColor() {
      if (this.item.Mobile && this.item.SmsAllowed) {
        return this.item.IsActive ? 'tertiary' : 'black';
      }
      else {
        return '';
      }

    }
  },

  watch: {},
};
</script>

<style></style>