<!--

ServiceSystem Service Provider
Qwips 2021

-->
<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
export default {};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@500&family=Source+Code+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");
$font-family: "Darker Grotesque", serif;
#inspire {
  font-family: $font-family, sans-serif;
}

.source-code {
  [class*="text-"] {
    font-family: "Source Code Pro" !important;
    font-size: 0.7em;
  }
  [text-narrow] {
    line-height: 1.8 !important;
  }
  background-color: var(--v-code-base);
}

.overflow-overlay {
  overflow: overlay !important;
}
.overflow-x-overlay {
  overflow-x: overlay !important;
}
.overflow-y-overlay {
  overflow-x: overlay !important;
}
@-moz-document url-prefix() {
  //Firefox does not support overlay overflow, and interprets it as none instead of auto.
  .overflow-overlay {
    overflow: auto !important;
  }
  .overflow-x-overlay {
    overflow-x: auto !important;
  }
  .overflow-y-overlay {
    overflow-x: auto !important;
  }
}
</style>