<template>
  <v-card height="auto" class="rounded-0" flat color="#fbfbfb">
    <v-card-title class="py-1" style="font-size: 15px;">{{ $t("text.Service") }} {{ $t("text.cost") }} (12 {{
      $t("text.months") }})</v-card-title>
    <v-card-text v-if="machineCost">
      <DxChart id="chart" :data-source="filteredMachineCost" palette="Violet">
        <DxSize :height="150" />
        <DxSeriesTemplate :customize-series="customizeSeries" name-field="MachineName" />
        <DxCommonSeriesSettings argument-field="Date" value-field="Value" type="stackedbar" :bar-width="15">
          <DxAggregation :enabled="true" aggregation-interval="hour" method="sum" />
        </DxCommonSeriesSettings>

        <DxArgumentAxis :aggregate-by-category="false" />
        <DxLegend vertical-alignment="right" horizontal-alignment="right" />
        <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" location="edge" />
      </DxChart>
    </v-card-text>
    <v-card-subtitle v-else>{{ $t("text.no_activities") }}</v-card-subtitle>
  </v-card>
</template>


<script>
import http from "@/api/client";
import {
  DxArgumentAxis,
  DxAggregation,
  DxTooltip,
  DxSeriesTemplate,
  DxLegend,
  DxCommonSeriesSettings,
  DxChart,
  DxSize,
} from "devextreme-vue/chart";

export default {
  name: "CostGraph",
  props: {},
  components: {
    DxArgumentAxis,
    DxTooltip,
    DxAggregation,
    DxChart,
    DxLegend,
    DxCommonSeriesSettings,
    DxSeriesTemplate,
    DxSize,
  },

  data() {
    return {
      machineCost: [],
      type: "stackedbar",
      customizeSeries: null,
    };
  },

  mounted() {
    this.get();
  },

  methods: {
    get() {
      http()
        .get("api2/customer/overview/machinecost")
        .then((response) => {
          this.machineCost = response.data.map((v) => {
            v.Date = new Date(v.Date);
            return v;
          });
          this.machineCost.push({
            Date: new Date(),
          });
        });
    },

    customizeTooltip(pointInfo) {
      return {
        text: `${pointInfo.seriesName} amount: ${parseInt(
          pointInfo.valueText
        ).toFixed(2)}`,
      };
    },
  },

  computed: {
    filteredMachineCost() {
      return this.machineCost.filter((v) => v.Value > 0);
    },
  },

  watch: {},
};
</script>


<style lang='scss'></style>