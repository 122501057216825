const routes = {
  path: "/component",
  name: "Component",
  component: () => import("@/views/ComponentViews/TokenRouterView.vue"),
  children: [
    {
      path: "1",
      component: () => import("@/views/ComponentViews/Component1.vue"),
    },
    {
      path: "2",
      component: () => import("@/views/ComponentViews/Component2.vue"),
    },
    {
      path: "Cart",
      component: () => import("@/views/ComponentViews/Component3.vue"),
    },
    {
      path: "MachineHistory",
      component: () => import("@/views/ComponentViews/Component4.vue"),
    },
    {
      path: "5",
      component: () => import("@/views/ComponentViews/Component5.vue"),
    },
  ]
};

export default routes;
