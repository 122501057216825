<template>
  <v-container fluid fill-height class="loginOverlay ma-auto">
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm4 md3>
        <v-card style="padding-top: 3em" flat>
          <v-img src="/api2/Report/logo" max-height="100" contain position="center" />
          <h3 class="text-center mt-3">{{ $t("partner.portal.title") }}</h3>
          <v-card-text class="pt-4">
            <div>
              <h2>Logg inn</h2>
              <p>{{ $t("partner.portal.login_continue") }}</p>
              <v-form v-model="valid" ref="form">
                <v-text-field ref="emailfield" :label="$t('partner.portal.login_email_description')" v-model="email"
                  :rules="emailRules" required outlined>
                </v-text-field>
                <v-text-field ref="passwordfield" :label="$t('partner.portal.login_password_description')"
                  v-model="password" min="8" :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (e1 = !e1)" :type="e1 ? 'password' : 'text'" :rules="passwordRules" counter
                  outlined required @keydown.enter="submit">
                </v-text-field>
                <v-layout justify-space-between>
                  <router-link to="/resetpassword">{{ $t("partner.portal.forgot_password") }}</router-link>
                  <v-btn @click="submit" color="primary" :class="{ 'white--text': valid, disabled: !valid }"
                    :disabled="loginInProgress" :loading="loginInProgress">{{ $t("partner.portal.loginbutton")
                    }}</v-btn>

                </v-layout>
              </v-form>
            </div>
          </v-card-text>
        </v-card>

        <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style lang="scss" scoped>
.md-card {
  width: 400px;
  margin: 4px;
  display: inline-block;
  vertical-align: top;
}

.logo {
  width: 100%;
}

#login {
  width: 500px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin: auto;
  margin-top: 200px;
  padding: 20px;
}
</style>
<script>
export default {
  name: "LoginForm",
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loginInProgress: false,
      valid: false,
      e1: true,
      password: "",
      passwordRules: [(v) => !!v || "Password is required"],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loginInProgress = true;
        // call login
        const inputUserName = this.$refs.emailfield.value;
        const inputPassword = this.$refs.passwordfield.value;
        this.$store
          .dispatch("partnerLogin", {
            username: inputUserName,
            password: inputPassword,
          })
          .then((result) => {
            if (result.success) {
              this.snackbarText = "Login success";
              this.snackbar = true;
            } else {
              this.snackbarText = result.errorMessage;
              this.snackbar = true;
            }
            this.loginInProgress = false;
          });
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>