import en from "@/assets/lang/en.json";
import no from "@/assets/lang/no.json";

import vuei18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(vuei18n)

export default new vuei18n({
  locale: localStorage.getItem('lang') || 'en',
  messages: {
    en: en,
    no: no,
    nb: no,
  }

})