<template>
  <v-card class="rounded-0 " :class="isPhoneBool ? 'pt-2' : 'pt-15'" flat min-height="calc(100vh - 92px)">

    <strong class="ml-4">{{ $t("text.delivery_address") }}</strong>
    <v-card class="ma-4 mb-6 mt-2 pa-2" flat outlined>
      <v-row>
        <v-col>
          <div>
            {{ $store.state.userInfo.Customer.DeliveryAdressStreet }}
          </div>
          <div>
            {{ $store.state.userInfo.Customer.DeliveryAdressZip }}
            {{ $store.state.userInfo.Customer.DeliveryAdressCity }}
          </div>
          <div>
            {{ $store.state.userInfo.Customer.DeliveryAdressCountry }}
          </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- 
    <strong class="ml-4">On site contact person</strong>
    <v-card class="ma-4 mb-6 mt-2 pa-2" flat outlined>
      <v-row>
        <v-col>
          <div>
            {{ $store.state.userInfo.Customer.DeliveryAdressStreet }}
          </div>
          <div>
            {{ $store.state.userInfo.Customer.DeliveryAdressZip }}
            {{ $store.state.userInfo.Customer.DeliveryAdressCity }}
          </div>
        </v-col>
        <v-col>
          <v-flex>{{ $t("text.will_be_ordered_by") }} : {{ $store.state.userInfo.Name }}</v-flex>
        </v-col>
      </v-row>
    </v-card>
    -->

    <v-textarea v-model="Comment" :label="$t('text.comment')" persistent-placeholder
      placeholder="Any additional details or information that can help your service provider fulfill the order ..."
      outlined class="mx-4 my-4" hide-details>
    </v-textarea>

    <small class="mx-4" style="font-weight: 500;">{{ $t("text.will_be_ordered_by") }}: {{ $store.state.userInfo.Name
      }}</small>

    <v-row no-gutters class="px-4 mt-12 pt-15" align="end">
      <v-col cols="auto">
        <h4>Total*</h4>
      </v-col>
      <v-col cols="auto" class="mx-1">
        <h6 class="pb-1" style="opacity: 0.7;">(inc, VAT)</h6>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <h4>{{ formatCurrency(getTotalSum) }}</h4>
      </v-col>
    </v-row>

    <v-row no-gutters class="px-4 py-1" align="end">
      <small style="opacity: 0.7; font-weight: 500;">Additional cost that are not calculated yet will be incurred
        on top of this
        price.</small>
    </v-row>

    <v-card-actions class="px-4">
      <WideBtn :text="$t('text.send_order')" color="var(--v-tertiary-base)" :loading="isInAsync" :disabled="!valid"
        @click="sendOrder()">
      </WideBtn>
    </v-card-actions>

    <v-snackbar v-model="toast">{{ toastMessage }}</v-snackbar>
  </v-card>
</template>


<script>
import http from "@/api/client";
import WideBtn from "@/components/WideBtn.vue";

export default {
  name: 'CartDetails',
  props: {},
  components: {
    WideBtn,
  },

  data() {
    return {
      toastMessage: "",
      toast: false,
      dirty: false,
      isInAsync: false,
      Comment: "",
      loading: false,
      valid: true,
    };
  },

  methods: {
    sendOrder() {
      this.isInAsync = true;
      http()
        .post("api2/customer/shoppingcart/checkout", {
          Id: this.shoppingCart.CartId,
          Comment: this.Comment,
        })
        .then((response) => {
          this.$store.dispatch("getShoppingCart");
          this.$router.push({
            name: "JobList",
            params: { jobId: response.data.Id },
          });
        });
    },
  },

  computed: {
    getTotalSum() {
      if (this.shoppingCart) {
        var sum = 0.0;
        this.shoppingCart.forEach((item) => {
          item.Items.forEach((v) => {
            sum += v.ArticlePrice * v.ArticleCount;
          });
        });
        return Math.round(sum * 100) / 100;
      }
      return 0;
    },

    shoppingCart() {
      if (this.$store.state.shoppingCart) {
        return this.$store.state.shoppingCart;
      }
      return [];
    },

    shoppingCartLoading() {
      return this.$store.state.shoppingCartLoading || false;
    },
  },

  watch: {},
};
</script>


<style lang='scss' scoped></style>