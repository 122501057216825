<template>
  <v-dialog max-width="800" v-model="dialog" @click:outside="hide">
    <v-card>
      <v-card-title>{{ $t("text.order_service") }}</v-card-title>
      <v-card-text>
        <v-combobox v-model="value" v-if="!hideSearch" :filter="filter" item-value="DisplayName" item-text="DisplayName"
          :hide-no-data="!search" :items="availableMachines" hide-selected :search-input.sync="search"
          :label="$t('text.search_machine')" hide-details multiple>
          <template v-slot:selection="{ attrs, item, parent }">
            <v-chip v-bind="attrs" label color="primary">
              <span class="pr-2">{{ item.DisplayName }}</span>
              <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-chip color="primary" outlined label>
              {{ item.DisplayName }}
            </v-chip>
            <v-spacer />
          </template>
        </v-combobox>
      </v-card-text>
      <v-card-text v-for="machine in value" :key="machine.Id">
        <MachineListItem :item="machine">
        </MachineListItem>
        <v-card-subtitle>
        </v-card-subtitle>
        <v-textarea v-model="machine.CustomerText" outlined
          :label="$t('text.error_description') + ' (' + $t('text.required') + ')'">
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" :disabled="!isValid || isInAsync || value.length < 1" @click="createJob"
          :loading="isInAsync">{{ $t("text.order_now") }}</v-btn>
        <v-btn @click="hide" :disabled="isInAsync">
          {{ $t("text.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import http from "@/api/client";
import MachineListItem from "@/components/ListItems/MachineListItem.vue";

export default {
  name: "NewJobDialog",
  props: {
    machine: {
      type: String,
      default() {
        return null;
      },
    },
    machines: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  components: {
    MachineListItem,
  },

  data() {
    return {
      hideSearch: false,
      isInAsync: false,
      search: null,
      availableMachines: [],
      dialog: true,
      value: [],
    };
  },

  created() {
    if (!this.machines) {
      http()
        .get("api2/customer/machines")
        .then((response) => {
          this.availableMachines = response.data;
        });
    } else {
      this.availableMachines = this.machines;
    }
    if (this.machine && this.availableMachines.length == 1) {
      this.value = [this.availableMachines[0]];
      this.hideSearch = true;
    }
  },

  methods: {
    createJob() {
      this.isInAsync = true;
      http()
        .post("api2/customer/jobs", {
          Machines: this.value,
        })
        .then(() => {
          this.isInAsync = false;
          this.$emit("exit", "Job added!");
        });
    },

    filter(item, queryText, itemText) {
      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },

    hide() {
      this.$emit("exit");
    },
  },

  computed: {
    isValid() {
      return this.value.every((v) => {
        return v.CustomerText;
      });
    },
  },

  watch: {
    value(val, prev) {
      if (val.length === prev.length) return;
      //this.filter = "";
      this.search = "";
      val.forEach((item, index) => {
        if (typeof item === "string") {
          val.splice(index, 1);
          return;
        }
      });
    },
  },
};
</script>