<template>
  <v-container fill-height>
    <v-row align="center" class="text-center" justify="center">
      <v-col cols="12" style="text-align: left">
        <v-img src="/api2/Report/logo" max-height="100" contain position="left" />
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col class="text-right"><a href="#" @click="setLang('en')" v-if="$i18n.locale != 'en'">ENG</a>
            <a href="#" @click="setLang('no')" v-if="$i18n.locale != 'no' && $i18n.locale != 'nb'">NO</a>
          </v-col>
        </v-row>
        <v-card style="margin: auto; text-align: left" :elevation="0">
          <v-card-title>
            <h2>{{ $t("home.welcome") }}</h2>
          </v-card-title>
          <v-card-subtitle class="pb-0">{{ $t("home.privacy.rulename") }}</v-card-subtitle>
          <v-card-text>{{ $t("home.privacy.description") }}
            <a href="https://portal.bfsn.no/media/11907/smart-service-privacy-policy.pdf">
              <img :src="require('../assets/pdf.gif')" height="16" width="16" class="mr-2" />
              {{ $t("home.privacy.rulename") }}
            </a>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" v-for="item in availableNavigationProperties" :key="item.name">
        <v-card :rounded="false">
          <v-card :href="item.link" :to="item.route" exact :rounded="false" flat>
            <v-card-title class="justify-center">
              <v-icon size="150" color="primary">{{ item.icon }}</v-icon>
              <h2 class="primary--text">{{ item.name }}</h2>
            </v-card-title>
            <v-card-subtitle style="overflow-y: auto; height: 130px; padding-top: 15px">{{ item.subtitle }}
            </v-card-subtitle>
            <v-divider />
            <v-card-text style="overflow-y: auto; height: 170px; padding-top: 15px" class="text-left">
              <ul>
                <li v-for="service in item.featureList" :key="service">
                  {{ service }}
                </li>
              </ul>
            </v-card-text>
            <v-divider />
          </v-card>
          <v-row class="pt-3" style="height: 100px;">
            <template v-if="item.links">
              <v-col v-for="link in item.links" :key="link.name" class="ma-auto">
                <a :href="link.href">
                  <v-img :src="link.image" max-width="130" max-height="60" contain class="ma-auto" />
                </a>
              </v-col>
            </template>
          </v-row>
        </v-card>
      </v-col>

    </v-row>
    <v-footer>Powered by Qwips</v-footer>
  </v-container>
</template>

<script>
// @ is an alias to /src
import http from "@/api/client";
export default {
  name: "PortalSelectionPage",
  data() {
    return {
      options: null,
      navLinks: [],
    };
  },
  components: {},
  computed: {
    availableNavigationProperties() {
      return this.navLinks.filter((e) => e.enabled);
    },
  },
  methods: {
    createLinks() {
      this.navLinks = [
        {
          enabled: false,
          id: "CustomerPortal",
          name: this.$t("home.customer.name"),
          route: "/customer",
          icon: "mdi-wechat",
          subtitle: this.$t("home.customer.description"),
          featureList: this.$t("home.customer.featurelist").split("|"),
          links: [],
        },
        {
          id: "ServiceProvider",
          enabled: false,
          name: this.$t("home.serviceprovider.name"),
          link: "/serviceproviderwebsite",
          icon: "mdi-toolbox",
          subtitle: this.$t("home.serviceprovider.description"),
          featureList: this.$t("home.serviceprovider.featurelist").split("|"),
        },
        {
          id: "Technician",
          enabled: false,
          name: this.$t("home.technician.name"),
          link: "https://technician.qwips.net/deeplinking",
          icon: "mdi-cellphone-arrow-down",
          subtitle: this.$t("home.technician.description"),
          links: [
            {
              name: "Apple",
              image: require("../assets/apple/get.svg"),
              href: "https://apps.apple.com/us/app/qwips-technician/id1541338722?app=itunes&ign-mpt=uo%3D4",
            },
            {
              name: "Google",
              image: require("../assets/android/get.png"),
              href: "https://play.google.com/store/apps/details?id=no.qwips.servicesystem",
            },
          ],
          featureList: this.$t("home.technician.featurelist").split("|"),
        },
      ];
      http()
        .get("api2/app/portal")
        .then((response) => {
          console.log('api2/app/portal', response.data)
          this.options = response.data;
          this.setLink(
            "ServiceProvider",
            true,
            this.options.ServiceProvider ? "serviceprovider" : null
          );
          this.setLink("CustomerPortal", this.options.CustomerPortal, this.options.CustomerPortalRedirect);
          this.setLink("Technician", this.options.Technician);

          var customerapp = this.options.CustomerApp;
          if (customerapp) {
            var portallink = this.navLinks.find(x => x.id == 'CustomerPortal')
            var applinks = [{
              name: "Apple",
              image: require("../assets/apple/get.svg"),
              href: "https://apps.apple.com/us/app/qwips-customer/id1659035989",
            },
            {
              name: "Google",
              image: require("../assets/android/get.png"),
              href: "https://play.google.com/store/apps/details?id=no.qwips.servicesystem.customer",
            },]
            if ((typeof customerapp === 'string' || customerapp instanceof String) && customerapp.includes('|')) {
              var splitstring = this.options.CustomerApp.split('|');
              console.log('splitstring', splitstring)
              applinks[0].href = splitstring[0]
              applinks[1].href = splitstring[1]
            }
            portallink.links = applinks
          }
        });
    },
    setLang(lang) {
      this.$root.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
      this.createLinks();
      //this.$router.go(this.$router.currentRoute);
    },
    setLink(name, enabled, newLink = null) {
      var nlink = this.navLinks.find((e) => e.id === name);
      if (nlink) {
        if (newLink != null && newLink != '') {
          nlink.link = newLink;
          nlink.route = "";
        }
        nlink.enabled = enabled;
      }
    },
  },
  created() {
    var lang = localStorage.getItem("lang");
    if (lang) {
      this.$i18n.locale = lang;
    } else {
      this.$i18n.locale = navigator.language.slice(0, 2);
    }
  },
  mounted() {
    this.createLinks();
  },
};
</script>
