<template>
  <v-container fluid class="pa-0" style="background-color: white; position: relative;">


    <v-app-bar elevation="1" color="white" app>
      <v-row no-gutters align="center" justify="space-between">
        <v-spacer></v-spacer>

        <v-text-field v-model="search" append-icon="mdi-magnify" :label="'Search'" single-line outlined
          style="max-width: 400px;" dense hide-details>
          <template v-slot:prepend-inner>
            <v-btn elevation="0" small class="mr-3" style="margin-top: -2px; margin-left: -6px; text-transform: none;">
              All products
              <v-icon size="18" class="ml-2">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center">
          <ShoppingCartButton />
          <v-card class="ml-8 pl-4 rounded-0" flat style="border-left: 1px solid #e0e0e0;">
            <DirectorySwitcher />
          </v-card>
        </div>
      </v-row>
    </v-app-bar>

    <v-row class="mt-2 px-4">
      <v-col cols="8">
        <v-card class="d-flex align-center justify-center" outlined height="220">
          <h1>Hero Banner</h1>
        </v-card>
      </v-col>
      <v-col cols="4" class="d-flex flex-column justify-space-between">
        <v-card class="pa-3 d-flex align-center justify-space-between" outlined>
          Organized product catalouge
          <v-icon>mdi-chevron-right</v-icon>
        </v-card>
        <v-card class="pa-3 d-flex align-center justify-space-between" outlined>
          Outlet
          <v-icon>mdi-chevron-right</v-icon>
        </v-card>
        <v-card class="pa-3 d-flex align-center justify-space-between" outlined>
          Predictively recomended parts
          <v-icon>mdi-chevron-right</v-icon>
        </v-card>
        <v-card class="pa-3 d-flex align-center justify-space-between" outlined>
          Current promotions
          <v-icon>mdi-chevron-right</v-icon>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="px-4">
      <v-card-text class="pb-0">
        Vendor recomended equipment
      </v-card-text>
      <v-col v-for="i in 4" :key="i">
        <ProductCard isModel></ProductCard>
      </v-col>
    </v-row>

    <v-row class="px-4">
      <v-card-text class="pb-0">
        Consumables
      </v-card-text>
      <v-col v-for="i in 5" :key="i">
        <ProductCard></ProductCard>
      </v-col>
    </v-row>

    <v-row class="px-4 mb-4">
      <v-card-text class="pb-0">
        Spare parts
      </v-card-text>
      <v-col v-for="i in 5" :key="i">
        <ProductCard></ProductCard>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import DirectorySwitcher from '@/components/Inputs/DirectorySwitcher.vue';
import ShoppingCartButton from '@/components/ShoppingCartButton.vue';
import ProductCard from '@/components/Cards/ProductCard.vue';


export default {
  name: 'Shop',
  props: {},
  components: {
    DirectorySwitcher,
    ShoppingCartButton,
    ProductCard,
  },

  data() {
    return {
      search: "",
    };
  },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped></style>