<template>
  <v-container fluid class="pa-0" style="width: 100% !important;">
    <v-row v-for="(cart, index) in shoppingCart" no-gutters :key="'cart-' + index" class="my-2">
      <v-col>
        <v-card class="mx-2" outlined>
          <v-row no-gutters align="center">
            <v-col cols="12">
              <MachineListItem :item="cart.Machine" class="pl-3 mt-2" @click="openItem(cart.Machine)">
              </MachineListItem>
            </v-col>
            <!--
            <v-col cols="1" class="d-flex justify-end pr-2">
              <v-btn icon small>
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </v-col>
             -->
          </v-row>


          <v-row no-gutters align="center">
            <v-divider class="mx-3"></v-divider>
            <v-col cols="auto" class="text-right mr-3">
              Machine total: {{ formatCurrency(getPartialSum(cart)) }}
            </v-col>
          </v-row>

          <v-list dense>
            <CartLineListItem v-for="(line) in cart.Items" :key="line.Id" :line="line"></CartLineListItem>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import CartLineListItem from "@/components/ListItems/CartLineListItem.vue";
import MachineListItem from "@/components/ListItems/MachineListItem.vue";

export default {
  name: "CartItemList",
  props: {},
  components: {
    CartLineListItem,
    MachineListItem,
  },

  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {
    getPartialSum(cart) {
      var sum = 0.0;
      if (cart) {
        cart.Items.forEach((v) => {
          sum += v.ArticlePrice * v.ArticleCount;
        });
        return (Math.round(sum * 100) / 100).toFixed(2);
      }
      return sum.toFixed(2);
    },

    openItem(item) {
      this.$router.push({ name: 'ViewMachine', params: { id: item.Id } });
    },
  },

  computed: {
    shoppingCart() {
      if (this.$store.state.shoppingCart) {
        return this.$store.state.shoppingCart;
      }
      return [];
    },

    shoppingCartLoading() {
      return this.$store.state.shoppingCartLoading || false;
    },
  },

  watch: {},
};
</script>


<style lang='scss' scoped></style>
