<template>
  <v-container fluid class="pa-0">
    <v-container>
      <v-row>
        <v-col cols="12" md="12">

        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import noimage from "@/assets/noimage.jpg";
import http from "@/api/client";
export default {
  name: "PartnerDashboard",
  data() {
    return {
    };
  },
  computed: {
    filteredMachineCost() {
      return this.machineCost.filter((v) => v.Value > 0);
    },
    jobs() {
      return this.overview.Machines.filter(
        (v) => v.ActivePartsJobs + v.ActiveServiceJobs > 0
      );
    },
    shoppingCart() {
      return this.$store.state.shoppingCart || [];
    },
    totalJobs() {
      var totalJobs = 0;
      if (this.overview) {
        this.overview.Machines.forEach((m) => {
          totalJobs += m.ActivePartsJob + m.ActiveServiceJobs;
        });
      }
      return totalJobs;
    },
  },
  mounted() {
  },
  methods: {
    customizeTooltip(pointInfo) {
      return {
        text: `${pointInfo.seriesName} amount: ${parseInt(
          pointInfo.valueText
        ).toFixed(2)}`,
      };
    },
    shoppingCartCount() {
      return this.$store.getters.shoppingCartCount;
    },
    imageLoadError(item) {
      item.ModelImage = noimage;
    },
    async updateOverViewAsync() {
      await http()
        .get("api2/partner/overview")
        .then((response) => {
          const overview = response.data;
          overview.Machines = overview.Machines.map((e) => {
            if (e.ModelImage == null) {
              e.ModelImage = noimage;
            }
            return e;
          });

          overview.Machines.sort(function (a, b) {
            var valueA = a.ActivePartsJobs + a.ActiveServiceJobs;
            var valueB = b.ActivePartsJobs + b.ActiveServiceJobs;

            if (valueA < valueB) {
              return 1;
            } else if (valueA > valueB) {
              return -1;
            } else {
              return 0;
            }
          });
          this.overview = overview;
        });

      http()
        .get("api2/customer/overview/machinecost")
        .then((response) => {
          this.machineCost = response.data.map((v) => {
            v.Date = new Date(v.Date);
            return v;
          });
          this.machineCost.push({
            Date: new Date(),
          });
        });
    },
    selectMachineClick(item) {
      this.$router.push({
        name: "CustomerManageServicePanel",
        params: { id: item.MachineId },
      });
    },
  },
};
</script>

<style></style>