<template>
  <v-container>
    <loading-box :loading="true" v-if="machine == null" />
    <v-card color="basil" outlined v-else-if="machine">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-row class="fill-height ma-0 text-right" align="center" dense>
              <v-col cols="6">{{ $t("text.facility") }}</v-col>
              <v-col cols="6">
                <editable-field :editable="editmode" v-model="machine.Facility"
                  @change="(value) => (machine.Facility = value)">
                </editable-field>
              </v-col>
              <v-col cols="6">{{ $t("text.purchase_date") }}</v-col>
              <v-col cols="6">
                <editable-date-field :editable="editmode" v-model="machine.BoughtDate"
                  @change="(value) => (machine.BoughtDate = value)">
                </editable-date-field>
              </v-col>
              <v-col cols="6">{{ $t("text.installation_date") }}</v-col>
              <v-col cols="6">
                <editable-date-field :editable="editmode" v-model="machine.InstallationDate"
                  @change="(value) => (machine.InstallationDate = value)">
                </editable-date-field>
              </v-col>
              <v-col cols="6">{{ $t("text.warrenty_expires") }}</v-col>
              <v-col cols="6">
                <editable-date-field :editable="editmode" v-model="machine.WarrantyExpiresDate"
                  @change="(value) => (machine.WarrantyExpiresDate = value)">
                </editable-date-field>
              </v-col>
              <v-col cols="6">
                <v-checkbox :label="$t('text.service_agreement')" :readonly="!editmode">
                </v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-checkbox v-model="machine.IsActive" :label="$t('text.active')" :readonly="!editmode">
                </v-checkbox>
              </v-col>
              <v-col cols="12">
                <!--
                <v-btn v-if="!editmode" @click="editmode = !editmode"
                  >Rediger</v-btn
                >-->
                <v-btn v-if="editmode && dirty" color="primary" @click="editmode = !editmode">
                  {{ $t("text.save") }}
                </v-btn>
                <v-btn v-if="editmode" @click="editmode = !editmode">
                  {{ $t('text.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <pre>{{machine}}</pre> -->
      <!--
      <TimeLine />
-->
    </v-card>
  </v-container>
</template>
<script>
import EditableLabel from "@/components/Inputs/EditableLabel";
import LoadingBox from "@/components/LoadingBox.vue";
import EditableDate from "@/components/Inputs/EditableDate.vue";
//import TimeLine from "@/components/TimeLine.vue";

export default {
  name: "ViewMachine",
  components: {
    "editable-field": EditableLabel,
    "editable-date-field": EditableDate,
    "loading-box": LoadingBox,
    //TimeLine,
  },
  data() {
    return {
      machine: this.$route.params.Machine,
      dirty: false,
      editmode: false,
    };
  },
  mounted() {
    this.init();
    if (!this.machine) this.$emit("updateMachine");
  },
  watch: {
    $route() {
      this.init();
    },
  },
  methods: {
    init() {
      this.machine = this.$route.params.Machine;
    },
  },
};
</script>