<template>
    <v-card v-if="grid" min-width="170" class="py-2 pa-3 ma-2 rounded-lg" flat color="#F6F5F5" @click="$emit('click')">
        <v-icon size="50" :color="color" class="mb-1 ml-n1">mdi-folder</v-icon>
        <p v-if="description" class="mb-0" style="opacity: 0.8; font-size: 11px;">
            {{ description }}
        </p>
        <h6 style="opacity: 0.9;">{{ name }}</h6>
        <p class="mb-0" style="opacity: 0.8; font-size: 12.5px;">{{ fileCount }} files</p>
    </v-card>

    <v-list-item v-else link class="ma-1 py-1 rounded-lg" style="background-color: #F6F5F5; max-width: 400px"
        @click="$emit('click')">
        <v-list-item-icon class="my-auto mr-3">
            <v-icon size="35" :color="color" class="mb-1 ml-n1">mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="py-0">
            <v-list-item-subtitle style="font-size: 11.5px;">
                {{ description }}
            </v-list-item-subtitle>
            <v-list-item-title style="font-size: 13px;">
                {{ name }}
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-auto">
            {{ fileCount }} files
        </v-list-item-action>
    </v-list-item>
</template>


<script>
export default {
    name: 'FolderThumb',
    props: {
        name: String,
        description: String,
        fileCount: Number,
        color: String,
        grid: Boolean,
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>