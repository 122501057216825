<template>
  <div>
    <p>A one time code was sent to <b style="color: purple">{{ email }}</b>.<br>
      Please enter that code below to continue.
    </p>
    <v-otp-input v-model="otp" :length="otpLength"></v-otp-input>

    <WideBtn :text="$t('text.confirm')" color="var(--v-tertiary-base)" :disabled="!otpFilled" :loading="taskInProgress"
      @click="validateOtp()">
    </WideBtn>
    <WideBtn :text="$t('text.cancel')" color="var(--v-tertiary-base)" :disabled="taskInProgress" :filled="false"
      @click="mode = null">
    </WideBtn>

    <v-snackbar v-model="snackbar" :color="success ? 'green' : 'error'">{{ snackbarText }}</v-snackbar>
  </div>
</template>


<script>
import http from "@/api/client";
import WideBtn from '@/components/WideBtn.vue';

export default {
  name: "OTPContent",
  components: {
    WideBtn,
  },

  data() {
    return {
      success: false,
      snackbar: false,
      snackbarText: "",
      taskInProgress: false,
      valid: false,
      otpLength: 6,
    };
  },

  mounted() { },

  methods: {
    validateOtp() {
      this.onStart();
      http()
        .get(`/api2/Accounts/ValidateOtp?email=${this.email}&code=${this.otp}`)
        .then(() => {
          this.onSuccess(this.$t("account.password_sent"), this.$t("account.password_sent_short"));
        })
        .catch((error) => {
          this.onError(error, error.response.data.Message);
        });
    },

    onStart() {
      this.taskInProgress = true;
      this.success = true;
      this.snackbar = false;
    },

    onSuccess(message, snacktext) {
      this.message = message;
      this.success = true;
      this.snackbarText = snacktext;
      this.snackbar = true;
      this.taskInProgress = false;
      this.mode = 'set';
    },

    onError(error, snacktext) {
      console.error(error);
      this.success = false;
      this.snackbarText = snacktext;
      this.snackbar = true;
      this.taskInProgress = false;
    },
  },

  computed: {
    otpFilled() {
      return this.otp.length === this.otpLength
    },

    otp: {
      get() {
        return this.getQueryParam("otp", "");
      },
      set(update) {
        this.setQueryParam("otp", update);
      },
    },

    email: {
      get() {
        return this.getQueryParam("email", "");
      },
      set(update) {
        this.setQueryParam("email", update);
      },
    },

    mode: {
      get() {
        return this.getQueryParam("mode", "");
      },
      set(update) {
        this.setQueryParam("mode", update);
      },
    },
  },

  watch: {
    otpFilled: function () {
      this.validateOtp();
    }
  },
};
</script>


<style lang="scss"></style>