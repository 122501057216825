import Vue from "vue";
import Vuex from "vuex";
import client from "@/api/client";
//import getUserInfo from "@/api/userinfo.api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: JSON.parse(localStorage.getItem("token")),
    userInfo: null,
    shoppingCart: null,
    shoppingCartLoading: false,
    cartError: null,
    openJobsCount: null,
  },

  mutations: {
    updateToken(state, response) {
      localStorage.setItem("token", JSON.stringify(response));
      state.token = response;
    },
    updateUserInfo(state, response) {
      state.userInfo = response;
      //console.log(state.userInfo)
    },

    cartStartUpdating(state) {
      state.shoppingCartLoading = true;
      state.cartError = null;
    },
    cartStopUpdating(state) {
      state.shoppingCartLoading = false;
    },
    cartSetError(state, error) {
      state.cartError = error;
    },
    updateShoppingCart(state, response) {
      state.shoppingCart = response;
      state.shoppingCartLoading = false;
    },

    setOpenJobsCount(state, count) {
      state.openJobsCount = count;
    }
  },

  actions: {
    updateToken({ commit }, response) { commit("updateToken", response); },

    logout({ commit }) { commit("updateToken", null); },

    async getUserInfo({ commit }) {
      await client()
        .get("/api2/customer/userinfo")
        .then((response) => {
          commit("updateUserInfo", response.data);
        });
      this.dispatch("getShoppingCart");
      this.dispatch("getOpenJobsCount");
      console.log("User info updated");
    },

    addCart({ commit }, item) {
      commit("cartStartUpdating");
      client()
        .post("api2/customer/shoppingcart", item)
        .then(() => {
          this.dispatch("getShoppingCart");
        });
    },

    getShoppingCart({ commit }) {
      commit("cartStartUpdating");
      client()
        .get("/api2/customer/shoppingcart")
        .then((response) => {
          commit("updateShoppingCart", response.data);
        })
        .catch((error) => commit("cartSetError", error))
        .finally(() => commit("cartStopUpdating"));
    },

    silentGetShoppingCart({ commit }) {
      client()
        .get("/api2/customer/shoppingcart")
        .then((response) => {
          commit("updateShoppingCart", response.data);
        });
    },

    getOpenJobsCount({ commit }) {
      client()
        .get("/api2/customer/jobs/openCount")
        .then((response) => {
          commit("setOpenJobsCount", response.data);
        });
    },

    async customerLogin({ commit }, { username, password }) {
      var data = new FormData();
      data.append("grant_type", "client_credentials");
      data.append("scope", "ServiceProvider.Customer");
      var tok = username + ":" + password;
      var encUserPass = btoa(tok);

      var headers = {
        Authorization: "Basic " + encUserPass,
      };
      var http = client();
      try {
        var response = await http.post("/api2/token", data, { headers });
        commit("updateToken", response.data);
        this.dispatch("getUserInfo");
        return { success: true };
      } catch (error) {
        return { success: false, errorMessage: "Invalid password" };
      }
    },

    async partnerLogin({ commit }, { username, password }) {
      var data = new FormData();
      data.append("grant_type", "client_credentials");
      data.append("scope", "ServiceProvider.Partner");
      var tok = username + ":" + password;
      var encUserPass = btoa(tok);

      var headers = {
        Authorization: "Basic " + encUserPass,
      };
      var http = client();
      try {
        var response = await http.post("/api2/token", data, { headers });
        commit("updateToken", response.data);
        this.dispatch("getUserInfo");
        return { success: true };
      } catch (error) {
        return { success: false, errorMessage: "Invalid password" };
      }
    },
  },

  getters: {
    shoppingCartCount(state) {
      if (state.shoppingCart) {
        var itemCount = 0;
        state.shoppingCart.forEach((c) => {
          c.Items.forEach((i) => {
            itemCount += i.ArticleCount;
          });
        });
        return itemCount;
      }
      return "";
    },
  },

  modules: {},
});
