<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot name="activator">
          <HoverListItem color="warning" icon="mdi-form-textbox-password" :text="$t('text.change_password')">
          </HoverListItem>
        </slot>
      </div>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-4">
        {{ $t("text.change_password") }}
      </v-card-title>
      <v-card-subtitle class="mt-4 pb-0">{{ $t("text.password_criteria") }}</v-card-subtitle>
      <v-form v-model="valid">
        <v-card-text>
          <v-text-field v-model="oldPassword" :label="$t('text.old_password')"
            :append-icon="showPw1 ? 'mdi-eye' : 'mdi-eye-off'" :type="showPw1 ? 'text' : 'password'"
            @click:append="showPw1 = !showPw1">
          </v-text-field>
          <v-text-field v-model="newPassword" :label="$t('text.new_password')"
            :append-icon="showPw2 ? 'mdi-eye' : 'mdi-eye-off'" :type="showPw2 ? 'text' : 'password'"
            :rules="passwordRules" @click:append="showPw2 = !showPw2">
          </v-text-field>
          <v-text-field v-model="newPasswordConfirm" :label="$t('text.confirm_password')"
            :append-icon="showPw3 ? 'mdi-eye' : 'mdi-eye-off'" :type="showPw3 ? 'text' : 'password'"
            :rules="confirmPasswordRules" @click:append="showPw3 = !showPw3">
          </v-text-field>
        </v-card-text>
      </v-form>
      <v-divider />
      <v-card-actions>
        <v-btn outlined color="primary" @click="dialog = false">Close</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="updatePassword" :disabled="!valid" :loading="passwordUpdating">{{
    $t("account.update_password") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import http from "@/api/client";
import HoverListItem from "@/components/ListItems/HoverListItem.vue";

export default {
  name: "ResetPasswordDialog",
  props: {},
  components: {
    HoverListItem,
  },

  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",

      passwordUpdating: false,
      dialog: false,

      showPw1: false,
      showPw2: false,
      showPw3: false,

      valid: false,
      oldPasswordRules: [
        (value) => !!value || "Please type password.",
        () => !!this.passwordUpdating || "Loading",
      ],
      passwordRules: [
        (value) => !!value || "Please type password.",
        (value) => (value && value.length >= 6) || "Minimum 6 characters",
        (value) =>
          (value && /[A-Z]{1}/.test(value)) || "At least one capital latter",
        (value) => (value && /\d/.test(value)) || "At least one digit",
        (value) =>
          (value && /[^A-Za-z0-9]/.test(value)) ||
          "At least one special character",
      ],
      confirmPasswordRules: [
        (value) => !!value || "Please type confirm password",
        (value) =>
          value === this.newPassword ||
          "The password confirmation does not match.",
      ],
    };
  },

  methods: {
    updatePassword() {
      if (this.valid) {
        var data = {
          currentPassword: this.oldPassword,
          newPassword: this.newPassword,
          newPasswordConfirm: this.newPasswordConfirm,
        };
        this.passwordUpdating = true;
        http()
          .post("/api2/Accounts/ChangePassword", data)
          .then((response) => {
            if (response.data.Success) {
              this.snackbarText = "Password changed";
              this.snackbar = true;
            } else {
              this.snackbarText = response.data.ErrorMessage;
              this.snackbar = true;
            }
            this.passwordUpdating = false;
            this.dialog = false;
          })
          .catch((error) => {
            this.snackbarText = error.data.ErrorMessage;
            this.snackbar = true;
            this.passwordUpdating = false;
          });
      }
    },
  },

  computed: {},

  watch: {
    dialog: function () {
      this.oldPassword = "";
      this.newPassword = "";
      this.newPasswordConfirm = "";
    },
  },
};
</script>


<style lang='scss' scoped></style>