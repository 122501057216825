<template>
  <div class="d-flex justify-center">
    <v-btn-toggle v-model="langcode"
                  @change="setLanguage()"
                  active-class="highlighted-lang"
                  id="langselector"
                  mandatory
                  style="background: transparent">
      <v-btn @click="setLanguage('en')"
             value="en">
        <v-img width="30"
               height="20"
               src="@/assets/lang/en.png">
        </v-img>
      </v-btn>
      <v-btn @click="setLanguage('no')"
             value="no">
        <v-img width="30"
               height="20"
               src="@/assets/lang/no.png">
        </v-img>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>


<script>
export default {
  name: "LanguageSelector",
  props: {},
  components: {},

  data() {
    return {
      langcode: localStorage.getItem("lang") || "en",
    };
  },

  mounted() {},

  methods: {
    setLanguage() {
      localStorage.setItem("lang", this.langcode);
      window.location.reload();
    },
  },

  computed: {},

  watch: {},
};
</script>


<style lang="scss">
#langselector .v-btn {
  border-color: transparent !important;
  background-color: transparent !important;
  height: 32px;
  padding-left: 6px;
  padding-right: 6px;
}
#langselector .v-btn:not(.highlighted-lang) {
  opacity: 0.6;
}
#langselector .highlighted-lang {
  border-color: var(--v-primary-base) !important;
  border-width: 2px !important;
  border-radius: 5px !important;
}
#langselector .highlighted-lang::before {
  background-color: transparent !important;
}
</style>