<template>
  <v-container ref="directorydropdownbtn"
               class="pa-0">

    <div v-if="directoryOptionsLoading || direcoryChangeLoading">
      <v-row>
        <v-col cols="auto">
          <v-skeleton-loader class="mt-2 mb-n1 mx-2"
                             type="avatar">
          </v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader class="mt-3 mr-2"
                             type="text">
          </v-skeleton-loader>
          <v-skeleton-loader class="mt-2 mr-2"
                             type="text">
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <v-menu v-else
            offset-y
            :min-width="menuWidth"
            :max-width="menuWidth"
            rounded="0">
      <template v-slot:activator="{ on, attrs }">
        <v-card :loading="direcoryChangeLoading ? 'primary' : ''"
                class="rounded-0"
                color="transparent"
                style="height: 64px;"
                flat>
          <v-container class="pa-0">
            <v-row no-gutters
                   style="height: 100%;">
              <v-list-item class="pa-0 pl-4 pr-1"
                           link
                           :style="(items && items.length > 1) ? 'max-width: 250px;' : 'max-width: 300px;'"
                           style="height: 64px;"
                           @click="$router.push({name: 'Organization'})">
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{directory.Name}}</v-list-item-title>
                  <v-list-item-subtitle>{{directory.OrganisationNumber}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <template v-if="items && items.length > 1">
                <v-divider vertical
                           inset>
                </v-divider>
                <v-list-item-action class="ma-0">
                  <v-btn icon
                         v-bind="attrs"
                         v-on="on">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-row>
          </v-container>

        </v-card>
      </template>
      <v-list-item v-if="items && items.length > 5"
                   style="background-color: white;">
        <v-text-field v-model="searchword"
                      placeholder="Search"
                      prepend-icon="mdi-magnify"
                      dense
                      hide-details
                      @click.stop="">
        </v-text-field>
      </v-list-item>
      <v-list class="py-0"
              style="overflow-y: overlay;"
              :style="(items && items.length > 5) ? 'max-height: calc(100vh - 112px);' : 'max-height: calc(100vh - 64px);'">
        <v-list-item v-for="(option, index) in filteredItems"
                     :key="index"
                     link
                     @click="changeDirectory(option)">
          <v-list-item-icon class="mr-4">
            <v-icon :color="option.Id == directory.Id ? 'primary' : ''"
                    v-text="option.Id == directory.Id ? ' mdi-check' : ' mdi-domain'">
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title :class="option.Id == directory.Id ? 'primary--text' : ''">{{option.Name}}</v-list-item-title>
            <v-list-item-subtitle :class="option.Id == directory.Id ? 'primary--text text--lighten-1' : ''">{{option.OrganisationNumber}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>


<script>
import client from "@/api/client";

export default {
  name: "DirectorySwitcher",
  props: {},
  components: {},

  data() {
    return {
      searchword: "",
      directoryOptionsLoading: false,
      direcoryChangeLoading: false,
      menuWidth: "100vw",
    };
  },

  mounted() {
    setTimeout(() => {
      this.getMenuWidth();
    }, 600);
    console.log(this.$route);
  },

  methods: {
    changeDirectory(e) {
      //console.log(e);
      if (e.Id != this.directory.Id) {
        this.direcoryChangeLoading = true;
        client()
          .post("/api2/Token/impersonate?customerId=" + e.Id)
          .then((response) => {
            //console.log(response);
            this.$store.dispatch("updateToken", response.data);
            var exceptions = [
              "CustomerMachineList",
              "Dashboard",
              "CustomerJobs",
              "Organization",
            ];
            if (!exceptions.includes(this.$route.name)) {
              this.$router.push({ name: "Dashboard" });
            }

            location.reload();
          })
          .catch((e) => console.error(e))
          .finally(() => {
            this.direcoryChangeLoading = false;
          });
      }
    },

    getMenuWidth() {
      var maxwidth = "100vw";
      var ref = this.$refs.directorydropdownbtn;
      if (ref) {
        var left = ref.__vue__.$el.getBoundingClientRect().x;
        var leftWithScrollbarOffset = left + 16;
        maxwidth = "calc(100vw - " + leftWithScrollbarOffset + "px)";
      }
      this.menuWidth = maxwidth;
      //console.log(this.$refs, ref, maxwidth, leftWithScrollbarOffset);
    },
  },

  computed: {
    directory() {
      return this.$store.state.userInfo.Customer;
    },

    filteredItems() {
      if (this.searchword) {
        return this.items.filter((x) =>
          x.Name.toLowerCase().includes(this.searchword.toLowerCase())
        );
      } else {
        return this.items;
      }
    },

    items() {
      //console.log("items", this.$store.state.userInfo.ImpersonationOptions);
      if (this.$store.state.userInfo?.ImpersonationOptions) {
        var list = this.$store.state.userInfo.ImpersonationOptions;
        var current = this.$store.state.userInfo.Customer;
        if (!list.find((x) => x.Id == current.Id)) {
          return [...list, this.$store.state.userInfo.Customer];
        } else {
          return list;
        }
      } else {
        return [this.$store.state.userInfo.Customer];
      }
    },
  },

  watch: {},
};
</script>