<template>
  <div>
    <v-form v-model="valid" ref="form">
      <!--
      <v-text-field v-model="email" ref="emailfield" :label="$t('text.email')" :rules="emailRules" required outlined
        dense>
      </v-text-field>
       -->
      <EmailField v-model="email">
      </EmailField>
      <v-card height="62" flat color="transparent">
        {{ message }}
      </v-card>

      <WideBtn :text="!success ? $t('text.send') : $t('text.send_again')"
        :color="!success ? 'var(--v-tertiary-base)' : 'var(--v-primary-base)'" :disabled="!valid"
        :loading="taskInProgress" @click="submit()">
      </WideBtn>
      <WideBtn :text="$t('text.back')" color="var(--v-tertiary-base)" :disabled="taskInProgress" :filled="false"
        @click="mode = null">
      </WideBtn>

      <v-snackbar v-model="snackbar" :color="success ? 'green' : 'error'">{{ snackbarText }}</v-snackbar>
    </v-form>
  </div>
</template>


<script>
import http from "@/api/client";
import WideBtn from '@/components/WideBtn.vue';
import EmailField from '@/components/Inputs/EmailField.vue';

export default {
  name: "SendResetContent",
  components: {
    WideBtn,
    EmailField,
  },

  data() {
    return {
      success: false,
      snackbar: false,
      snackbarText: "",
      taskInProgress: false,
      valid: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      message: '',
    };
  },

  mounted() { },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        //this.sendResetToDefaultPasword(this.email)
        this.sendOtpMail()
      }
    },

    sendResetToDefaultPasword(email) {
      this.onStart();
      http()
        .post("/api2/Accounts/RecoverPassword", { username: email })
        .then(() => {
          this.onSuccess(this.$t("account.password_sent"), this.$t("account.password_sent_short"));
        })
        .catch((error) => {
          this.onError(error, error.response.data.Message);
        });
    },

    sendOtpMail() {
      this.onStart();
      http()
        .post("/api2/Accounts/RecoverPassword", { username: this.email })
        .then(() => {
          this.onSuccess(this.$t("account.password_sent"), this.$t("account.password_sent_short"));
        })
        .catch((error) => {
          this.onError(error, error.response.data.Message);
        });
    },

    onStart() {
      this.taskInProgress = true;
      this.success = true;
      this.snackbar = false;
    },

    onSuccess(message, snacktext) {
      this.message = message;
      this.success = true;
      this.snackbarText = snacktext;
      this.snackbar = true;
      this.taskInProgress = false;
      this.mode = 'otp';
    },

    onError(error, snacktext) {
      console.error(error);
      this.success = false;
      this.snackbarText = snacktext;
      this.snackbar = true;
      this.taskInProgress = false;
    },
  },

  computed: {
    email: {
      get() {
        return this.getQueryParam("email", "");
      },
      set(update) {
        this.setQueryParam("email", update);
      },
    },

    mode: {
      get() {
        return this.getQueryParam("mode", "");
      },
      set(update) {
        this.setQueryParam("mode", update);
      },
    },
  },

  watch: {},
};
</script>


<style lang="scss"></style>