<template>
    <v-card outlined @click="e => $emit('click', e)">
        <v-container>
            <v-row>
                <v-col class="d-flex justify-center">
                    <MachineModelImage v-if="isModel" :size="120" :relativePath="null">
                    </MachineModelImage>
                    <ArticleImage v-else :size="80" :relativePath="null">
                    </ArticleImage>
                </v-col>
            </v-row>
            <v-list-item class="px-0" dense>
                <v-list-item-content>
                    <v-list-item-subtitle style="opacity: 0.75;">{{ isModel ? 'ProductNumber' : 'ArticleNumber'
                        }}</v-list-item-subtitle>
                    <v-list-item-title>{{ isModel ? 'ModelName' : 'ArticleDescription' }}</v-list-item-title>
                    <v-list-item-subtitle>SupplierName</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-row>
                <v-col class="text-end">
                    100,-
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>


<script>
import ArticleImage from '@/components/Cards/ArticleImage.vue';
import MachineModelImage from '@/components/Cards/MachineModelImage.vue';

export default {
    name: 'ProductCard',
    props: {
        isModel: Boolean,
    },
    components: {
        ArticleImage,
        MachineModelImage,
    },

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>