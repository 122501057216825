<template>
  <QImage2 :relativePath="relativePath" :size="size" :fallbackIcon="'mdi-tag'"></QImage2>
</template>

<script>
import QImage2 from "@/components/Cards/QImage2.vue";

export default {
  name: "ArticleImage",
  props: {
    size: Number,
    relativePath: String,
  },
  components: {
    QImage2,
  },
  mixins: [],

  data() {
    return {
    };
  },

  mounted() {
    //console.log(this.relativePath);
  },

  methods: {},

  computed: {},

  watch: {},
};
</script>