<template>
  <v-container fluid>
    providers info
    <v-row>
      <v-col>
        <v-card>
          provider 1
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          provider 2
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card width="fit-content" class="px-3 my-2">Order new equipment or parts</v-card>
        <v-card width="fit-content" class="px-3">Order new service</v-card>
      </v-col>
      <v-col>
        <v-card>
          tutorial
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          machines
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          recent orders
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'CustomerFrontPage',
  props: {},
  components: {},

  data() {
    return {};
  },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped></style>