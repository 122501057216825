<template>
  <!--
  <QTextArea :value="$route.query['filter-keyword']" :label="'keyword'" clearable externaledit allowexternaloverride
    class="ml-2 mr-4 mt-3 mb-4"
    @DebouncedInput="e => $router.replace({ query: { ...$route.query, 'filter-keyword': (e ? e : undefined) } })">
    <template v-slot:prepend-outer>
      <v-icon size="20">mdi-magnify</v-icon>
    </template>
  </QTextArea>
  -->
  <v-text-field v-model="keyword" class="searchfield" dense single-line hide-details :prepend-inner-icon="'mdi-magnify'"
    filled background-color="#f8f8f8" outlined clearable :label="$t('text.search_in_list')" type="text"
    :style="limitWidth ? 'max-width: 250px;' : ''">
  </v-text-field>
</template>


<script>
import debounce from "debounce";

export default {
  name: "FilterSearch",
  props: {
    limitWidth: {
      type: Boolean,
      default: true,
    },
    queryKey: {
      type: String,
      default: 'filter-keyword',
    },
  },
  components: {},

  data() {
    return {
    };
  },

  mounted() { },

  methods: {
    debounceValue: debounce(function (value) {
      this.$router.replace({ query: { ...this.$route.query, [this.queryKey]: (value ? value : undefined) } })
    }, 700),
  },

  computed: {
    keyword: {
      get() {
        return this.$route.query[this.queryKey];
      },
      set(newValue) {
        this.debounceValue(newValue);
      }
    }
  },

  watch: {},
};
</script>


<style lang="scss">
.v-text-field.searchfield .v-input__control .v-input__slot {
  min-height: 0 !important;
  padding: 0 8px !important;
  margin-bottom: 2px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 14px !important;
  border-radius: 6px;
}

.v-text-field.searchfield .v-input__control .v-input__slot .v-input__prepend-inner {
  margin-top: 4px !important;
  margin-right: 4px !important;
}

.v-text-field.searchfield .v-input__control .v-input__slot .v-input__append-inner {
  margin-top: 4px !important;
}

.v-text-field.searchfield .v-input__control .v-input__slot label {
  margin-top: -4px !important;
  font-size: 14px !important;
}

.v-text-field.searchfield .v-input__control .v-input__slot label.v-label--active {
  margin: 0 0 0 5px !important;
}

.v-text-field__details {
  margin: 2px !important;
}
</style>