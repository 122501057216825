import PartnerHome from "@/views/Partner/PartnerHome.vue";
import PartnerJobs from "@/views/Partner/PartnerJobs.vue";
import PartnerDashboard from "@/views/Partner/PartnerDashboard.vue";

const routes = {
  path: "/partner",
  component: PartnerHome,
  children: [
    {
      path: "dashboard",
      component: PartnerDashboard
    },
    {
      path: "jobs",
      component: PartnerJobs
    },
  ]
};

export default routes;
