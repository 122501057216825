<template>
    <v-chip class="px-2 text-truncate" style="border-radius: 4px;" small>
        {{ typeName }}
    </v-chip>
</template>

<script>
export default {
    name: 'JobTypeChip',
    props: {
        typeId: Number,
        typeName: String,
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>