<template>
  <v-dialog max-width="800"
            v-model="dialog"
            @click:outside="hide">
    <v-card>
      <v-card-text v-for="machine in value"
                   :key="machine.Id"
                   class="text-left">
        <v-card-title>
          {{ machine.DisplayName }}
          <v-spacer />
          {{ machine.Supplier && machine.Supplier.Name }}
        </v-card-title>
        <v-card-subtitle>
          <v-row>
            <v-col>{{ $t("text.serial_number") }}: {{ machine.SerialNumber }}</v-col>
            <v-col class="text-right">{{ $t("text.purchase_date") }}:
              {{ new Date(machine.BoughtDate).toLocaleDateString() }}</v-col>
          </v-row>
        </v-card-subtitle>
        <v-textarea v-model="machine.CustomerText"
                    outlined
                    :label="$t('text.error_description') + ' / ' + $t('text.comment') + ' (' + $t('text.required') + ')'">
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary"
               :disabled="!isValid || isInAsync"
               @click="createJob"
               :loading="isInAsync">{{ $t("text.order_now") }}</v-btn>
        <v-btn @click="hide"
               :disabled="isInAsync">
          {{$t("text.close")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import http from "@/api/client";

export default {
  name: "NewJobDialogEx",
  props: {
    machine: {
      type: String,
      default() {
        return null;
      },
    },
    machines: {
      type: Array,
      default() {
        return null;
      },
    },
  },

  data() {
    return {
      hideSearch: false,
      isInAsync: false,
      search: null,
      availableMachines: [],
      selectedMachines: [],
      dialog: true,
      value: [],
    };
  },

  created() {
    if (!this.machines) {
      http()
        .get("api2/customer/machines")
        .then((response) => {
          this.availableMachines = response.data;
        });
    } else {
      this.availableMachines = this.machines;
    }
    if (this.machine && this.availableMachines.length == 1) {
      this.value = [this.availableMachines[0]];
      this.hideSearch = true;
    }
  },

  methods: {
    createJob() {
      this.isInAsync = true;
      http()
        .post("api2/customer/jobs", {
          Machines: this.value,
        })
        .then(() => {
          this.isInAsync = false;
          this.$emit("exit", "Job added!");
        });
    },
    filter(item, queryText, itemText) {
      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    hide() {
      this.$emit("exit");
    },
  },

  computed: {
    isValid() {
      var isValid = true;
      if (this.value.length > 0) {
        this.value.forEach((v) => {
          if (!v.CustomerText) {
            isValid = false;
            return;
          }
        });
      }
      return isValid;
    },
  },

  watch: {
    value(val, prev) {
      if (val.length === prev.length) return;
      //this.filter = "";
      this.search = "";
      val.forEach((item, index) => {
        if (typeof item === "string") {
          val.splice(index, 1);
          return;
        }
      });
    },
  },
};
</script>