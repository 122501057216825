<template>
  <v-container fill-height fluid v-show="displayBool">
    <v-row no-gutters>
      <v-col align="center" justify="center">
        <div>
          <v-progress-circular v-if="!completedBool" :size="small ? 50 : 80" :width="4" color="primary" indeterminate>
          </v-progress-circular>
          <v-icon v-else :color="successBool ? 'green' : 'red'" :size="small ? 50 : 100"
            v-text="successBool ? 'mdi-check' : 'mdi-close'" />
          <component :is="texttype" class="mt-4" v-text="loadingtext" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["displayBool", "displayText", "completedBool", "successBool", "successText", "failedText", "small"],
  computed: {
    texttype() {
      if (this.small) {
        return "h3";
      } else {
        return "h2";
      }
    },
    loadingtext() {
      if (!this.completedBool) {
        return this.displayText != undefined ? this.displayText : "Loading...";
      } else {
        if (this.successBool) {
          return this.successText ? this.successText : "Complete";
        } else {
          return this.failedText ? this.failedText : "Error";
        }
      }
    },
  },
};
</script>
