import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#427aaf', //#4b39ef FF theme color
        accent: "#ff8e00",
        secondary: "#EC6906", //Orange
        tertiary: "#4b39ee", //Purple
        quaternary: "#44AF42", //Green
        quinary: "#f1b202", //yellow
        success: "#4CAF50",
        info: "#2e8ac0",
        warning: "#ff8e00",
        error: "#ff5252",
        disabled: "#CCC",

        //unplanned:
        jobstatus_forecast_color: "#8ba631",
        jobstatus_new_color: "#49d99c",
        //being worked on:
        jobstatus_planned_color: "#daaa3f",
        jobstatus_drive_color: "#c96198",
        jobstatus_in_progress_color: "#8256d0",
        jobstatus_waiting_color: "#cc6b2c",
        //finished:
        jobstatus_completed_color: "#316DCA",
        jobstatus_warranty_color: "#cc6b2c",
        jobstatus_approved_color: "#44AF42",
        jobstatus_closed_color: "#969696",

      },
    },
  },
});
