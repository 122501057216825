import i18n from '@/plugins/i18n.js'

import EmptyRouterView from "@/views/EmptyRouterView.vue";
import CustomerPortalWrapper from "@/views/Customer/CustomerPortalWrapper.vue";

import FrontPage from "@/views/Customer/FrontPage.vue";

//machine
import MachineList from "@/views/Customer/Equipment/MachineList2.vue";
/**/ //view machine
/**/ import ViewMachine from "@/views/Customer/Equipment/ViewMachineWrapper.vue";
/**/ /**/ import GeneralPanel from "@/views/Customer/Equipment/GeneralPanel.vue";
/**/ /**/ import ServicePanel from "@/views/Customer/Equipment/ServicePanel.vue";
/**/ /**/ import PartsPanel from "@/views/Customer/Equipment/PartsPanel.vue";
/**/ /**/ /**/ import PartInfoDialog from "@/components/Dialogs/PartInfoDialog.vue";
/**/ /**/ import ResourcesPanel from "@/views/Customer/Equipment/ResourcesPanel.vue";
/**/ /**/ import ManualsPanel from "@/views/Customer/Equipment/ManualsPanel.vue";
/**/ /**/ import FormsPanel from "@/views/Customer/Equipment/FormsPanel.vue";
/**/ /**/ import PartDocumentPanel from "@/views/Customer/Equipment/PartDocumentPanel.vue";
/**/ /**/ /**/ import PartDocumentDialog from "@/components/Dialogs/PartDocumentDialog.vue";

//job
import JobList from "@/views/Customer/Orders/JobList.vue";

//shop
//TODO: Catalouge page
import Shop from "@/views/Customer/Shop/Shop.vue";
import ShoppingCart from "@/views/Customer/Shop/ShoppingCart.vue";

//more
import Organization from "@/views/Customer/Organization.vue";
import Account from "@/views/Customer/Account.vue";



const routes = {
  path: "/customer",
  redirect: { name: 'FrontPage' },
  component: CustomerPortalWrapper,
  children: [

    {
      meta: {
        displayName: 'FrontPage',
      },
      name: "FrontPage",
      path: "-",
      redirect: { name: 'MachineList' },
      component: FrontPage,

    },

    //machines ------------------------------------------------------------
    {
      name: "Machines",
      path: "machines",
      redirect: { name: 'MachineList' },
      component: EmptyRouterView,
      children: [
        {
          meta: {
            displayName: i18n.t("text.equipment_list"),
          },
          name: "MachineList",
          component: MachineList,
          path: "list",
        },
        {
          name: "ViewMachine",
          path: ":id",
          redirect: { name: 'GeneralPanel' },
          component: ViewMachine,
          children: [
            {
              name: "GeneralPanel",
              component: GeneralPanel,
              path: "general",
              props: true,
            },
            {
              name: "ServicePanel",
              component: ServicePanel,
              path: "service/:jobId?"
            },
            {
              name: "PartsPanel",
              component: PartsPanel,
              path: "parts",
              children: [
                {
                  name: "PartInfoDialog",
                  component: PartInfoDialog,
                  path: ":part",
                },
              ],
            },
            {
              name: "ResourcesPanel",
              component: ResourcesPanel,
              path: "resources",
            },
            {
              name: "ManualsPanel",
              component: ManualsPanel,
              path: "manuals",
            },
            {
              name: "FormsPanel",
              component: FormsPanel,
              path: "forms",
            },
            {
              name: "PartDocumentPanel",
              path: "partsdocuments",
              component: PartDocumentPanel,
              children: [
                {
                  name: "PartDocumentDialog",
                  component: PartDocumentDialog,
                  path: ":modelid/:documentid",
                },
              ],
            },
          ],
        },
      ]
    },

    //jobs ------------------------------------------------------------
    {
      meta: {
        displayName: i18n.t("text.orders"),
      },
      path: "jobs/:jobId?",
      name: "JobList",
      component: JobList,
    },

    //shop ------------------------------------------------------------
    {
      meta: {
        displayName: i18n.t("text.shop"),
      },
      name: "Shop",
      path: "shop",
      redirect: { name: 'ShoppingCart' },
      component: Shop,
    },
    {
      meta: {
        displayName: i18n.t("text.shopping_cart"),
      },
      name: "ShoppingCart",
      path: "shop/cart",
      component: ShoppingCart,
    },

    //more ------------------------------------------------------------
    {
      meta: {
        displayName: i18n.t("text.company"),
      },
      name: "Organization",
      path: "organization",
      component: Organization,
    },
    {
      meta: {
        displayName: i18n.t("text.account"),
      },
      path: "account",
      component: Account,
    },

  ],
};

export default routes;
