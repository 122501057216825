<template>
  <v-card class="UserListItem" :flat="true"> <!-- @click="e => $emit('click', e)" -->
    <v-container class="py-2 px-0" fluid>

      <v-row justify="space-between" align="center" no-gutters class="px-3">
        <v-col cols="auto">
          <slot name="avatar" />
        </v-col>

        <v-col cols="0">
          <div class="py-1 px-1 nowraptext">
            <slot name="name" />
          </div>
          <div class="py-1 px-1 nowraptext" style="opacity: 0.8;">
            <slot name="email" />
          </div>
        </v-col>

        <v-col cols="auto" style="min-width: 120px;">
          <slot name="usertype" />
        </v-col>
        <!--
        <v-col cols="auto" style="width: 50px;">
          <slot name="actions" />
        </v-col>
        -->
      </v-row>

    </v-container>

    <v-divider :style="'border-color: rgb(0 0 0 /' + (header ? 40 : 10) + '%);'" />
  </v-card>
</template>


<script>
export default {
  name: "UserListItemSlotTemplate",
  props: {
    header: Boolean,
  },
  components: {},

  data() {
    return {
    };
  },

  mounted() { },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped>
.UserListItem.deactivated {
  background-color: rgb(243, 243, 243);
}

.UserListItem:not(.deactivated) {
  background-color: white;
}
</style>