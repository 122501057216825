<template>
  <v-text-field
    v-model="text"
    dense
    hide-details
    :placeholder="placeholder"
    :readonly="!editable"
    :solo="!editable"
    :flat="!editable"
    @change="$emit('change', text)"
  ></v-text-field>
  <!-- v-if="editable" <div v-else>{{value}}</div>-->
</template>
<script>
export default {
  name: "EditableLabel",
  data() {
    return {
      text: this.value,
    };
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>