<template>
    <v-chip :color="statuscolor(statusId)" dark style="border-radius: 5px;" small>
        {{ statusName }}
    </v-chip>
</template>


<script>
export default {
    name: 'JobStatusChip',
    props: {
        statusId: Number,
        statusName: String,
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>