<template>
  <div class="d-flex flex-column align-center pt-10">
    <slot name="icon">
      <v-icon size="150" color="disabled" v-text="preFilledIcon" />
    </slot>
    <h2 class="mt-3 px-4 useThemeTextColor not_too_wide_text" style="max-width:600px;">
      <slot name="title">
        {{ preFilledTitle }}
      </slot>
    </h2>
    <p class="py-3 mb-3 not_too_wide_text" style="max-width:600px;">
      <slot name="message">
        {{ preFilledMessage }}
      </slot>
    </p>
    <slot name="actions">
    </slot>
  </div>
</template>

<script>
export default {
  props: ["icon", "title", "message"],

  data() {
    return {};
  },

  mounted() { },

  methods: {},

  computed: {
    preFilledIcon() {
      if (this.icon) {
        return this.icon;
      } else {
        return "mdi-progress-question";
      }
    },

    preFilledTitle() {
      if (this.title) {
        return this.title;
      } else if (this.title === false) {
        return "";
      } else {
        return "No elements found.";
      }
    },

    preFilledMessage() {
      if (this.message) {
        return this.message;
      } else if (this.message === false) {
        return "";
      } else {
        return "We could not find any elements matching your spesifications.";
      }
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
.not_too_wide_text {
  max-width: 400px;
}

@media only screen and (max-width: 1024px) {
  .not_too_wide_text {
    max-width: 90vw !important;
  }
}
</style>
