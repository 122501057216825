<template>
  <v-hover v-slot="{ hover }">
    <v-list-item @click="$emit('click')">
      <v-list-item-content class="pa-0 ma-0">
        <v-list-item-title v-text="text"
                           :class="(hover ? color : '#5f5f5f') + '--text'">
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon v-text="icon"
                :color="hover ? color : null"
                size="22" />
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>


<script>
export default {
  name: "HoverListItem",
  props: {
    color: {
      default: "primary",
      type: String,
    },
    text: {
      default: 'Unused "text" prop',
      type: String,
    },
    icon: {
      default: "mdi-alert",
      type: String,
    },
  },
  components: {},

  data() {
    return {};
  },

  mounted() {},

  methods: {},

  computed: {},

  watch: {},
};
</script>

<style lang="scss">
</style>