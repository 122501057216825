<template>
  <v-container>
    <div v-for="item in parentJobs" :key="item.status">
      <v-card :color="getChildJobStatusColor(item.items[0])" dark><v-card-title class="status-title">{{
      item.status
    }}<v-spacer></v-spacer>1</v-card-title></v-card>
      <v-card ripple class="hover-card" :elevation="1" href="#" v-for="job in item.items" :key="job.Id">
        <v-card-title class="hover-title m-0 p-0">
          {{ job.DisplayName }}
          <v-spacer></v-spacer>
          <v-chip outlined label>{{ job.JobTypeName }}</v-chip>
          <v-chip :color="getChildJobStatusColor(job)" outlined label class="ml-1">
            {{ job.StatusName }}
          </v-chip>
          <v-icon>mdi-arrow-right</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="hover-text mt-2">
          <v-row dense>
            <v-col>
              <v-row no-gutters dense v-for="machineJob in job.Jobs" :key="machineJob.Id">
                <v-col cols="12" class="g-0">
                  <strong>
                    {{ machineJob.DisplayName }}
                  </strong>
                  <v-card-subtitle class="m-0 p-0 hover-subtitle">
                    {{ machineJob.CustomerComment }}
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-card-text class="hover-text" right>
                <v-row>
                  <v-col>
                    <div>{{ job.DeliveryAddress?.Street }}</div>
                    <span>{{ job.DeliveryAddress?.Zip }} </span>
                    <span>{{ job.DeliveryAddress?.City }}</span>
                    <div>{{ job.DeliveryAddress?.Country }}</div>
                  </v-col>
                  <v-col class="text-right">
                    <div v-for="appointment in getMyAppointments(job)" :key="appointment.Id">
                      {{ (new Date(appointment.StartTime)).toLocaleString() }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </div>
    <pre>{{ parentJobs }}</pre>
    <!--

      <v-card :loading="loading ? 'orange' : false" loader-height="10">
        <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $t("text.service_overview") }}</v-toolbar-title>
        
        <v-spacer></v-spacer>
        
        <v-scale-transition>
          <v-btn text @click="updateJobs">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-scale-transition>
      </v-toolbar>
      <v-card-text>
        <v-layout column v-resize="onResize">
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('text.search_list')" single-line
          hide-details></v-text-field>
          <v-data-table hide-default-footer :custom-filter="searchFilter" :search="search" v-resize="onResize"
          :headers="parentJobsHeader" :items="parentJobs" :single-expand="true" :expanded.sync="expanded"
          item-key="Id" @click:row="(item) => openJob(item)" :hide-default-headers="isMobile" :class="{
            mobile: isMobile,
            'row-pointer': true,
            'table-striped': true,
            nomobile: !isMobile,
    }">
    <template v-slot:top>
    </template>

<template v-slot:item="{ item }" v-if="isMobile">
      <tr>
        <td>
                  <ul class="flex-content">
                    <li class="flex-item" :data-label="parentJobsHeader[1].text">
                      {{ item[parentJobsHeader[1].value] }}
                    </li>
                    <li class="flex-itemrow" :data-label="parentJobsHeader[0].text">
                      <v-badge v-for="inverseItem in item.Jobs" :key="inverseItem.Id" overlap dot
                      :color="computeFlagColor(inverseItem.Flag)" bordered class="ma-1">
                      <v-chip :color="getChildJobStatusColor(inverseItem)" outlined label class="ml-1"
                      @click.stop="showJob(item)">
                      {{ inverseItem.MachineName }}
                    </v-chip>
                  </v-badge>
                </li>
                <li class="flex-item" :data-label="parentJobsHeader[2].text">
                      {{ item[parentJobsHeader[2].value] }}
                    </li>
                    <li class="flex-item" :data-label="parentJobsHeader[3].text">
                      {{ item[parentJobsHeader[3].value] }}
                    </li>
                    <li class="flex-item" :data-label="parentJobsHeader[4].text">
                      {{ item[parentJobsHeader[4].value] }}
                    </li>
                  </ul>
                </td>
              </tr>
            </template>

<template v-slot:[`item.DisplayName`]="{ item }">
              <v-badge v-for="inverseItem in item.Jobs" :key="inverseItem.Id" overlap dot
              :color="computeFlagColor(inverseItem.Flag)" bordered class="ma-1">
              <v-chip :color="getChildJobStatusColor(inverseItem)" outlined label class="ml-1"
              @click.stop="showJob(item)">
              {{ inverseItem.MachineName }}
                </v-chip>
              </v-badge>
            </template>
<template v-slot:[`item.CreatedDate`]="{ value }">{{
      new Date(value).toLocaleDateString()
    }}</template>
<v-alert slot="no-results" :value="true" icon="mdi-alert">Your search for "{{ search }}" found no
  results.</v-alert>
</v-data-table>
</v-layout>
</v-card-text>
</v-card>
-->
    <new-job-dialog v-if="newjobdialog" @exit="jobdialogexit" />
    <router-view></router-view>
    <v-snackbar v-model="toast">{{ toastMessage }}</v-snackbar>
  </v-container>
</template>


<script>
import http from "@/api/client";

export default {
  name: "PartnerJobs",
  components: {
  },

  data() {
    return {
      toast: false,
      toastMessage: "",
      newjobdialog: false,
      isMobile: false,
      selectedJob: {},
      jobdialog: false,
      expanded: [],
      loading: false,
      jobs: [],
      search: "",
      parentJobsHeader: [
        { text: this.$t("text.created_date"), value: "CreatedDate" },
        { text: this.$t("text.machine"), value: "DisplayName" },
        { text: this.$t("text.priority"), value: "JobPriority.Name" },
        { text: this.$t("text.type"), value: "JobTypeName" },
        { text: this.$t("text.status"), value: "StatusName" },
      ],
    };
  },

  mounted() {
    this.updateJobs();
  },

  methods: {
    computeFlagColor(num) {
      if (num == 0) {
        return "primary";
      } else if (num == 1) {
        return "warning";
      } else if (num == 2) {
        return "success";
      } else {
        return "error";
      }
    },

    searchFilter(value, search, item) {
      var searchData = "";

      item.InverseParent.map((v) => v.Machine).forEach((sData) => {
        searchData += sData.DisplayName.toLowerCase();
      });

      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        searchData.indexOf(search.toLowerCase()) !== -1
      );
    },

    jobdialogexit(val) {
      if (val) {
        this.toastMessage = val;
        this.toast = true;
        this.updateJobs();
      }
      this.newjobdialog = false;
    },

    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },

    showJob() { },

    openJob(item) {
      console.log("openJob", item)
    },

    getChildJobStatusColor(childJob) {
      if (childJob.StatusId <= 10) {
        return "";
      } else if (childJob.StatusId <= 20) {
        return "primary";
      } else if (childJob.StatusId == 50) {
        return "success";
      }
      return "";
    },

    clicked(value) {
      this.expanded = [value];
    },

    updateJobs() {
      this.loading = true;
      return http()
        .get("api2/partner/jobs")
        .then((response) => {
          this.jobs = response.data.ListOfParentJobs;
          this.loading = false;
          return response;
        });
    },
    getMyAppointments(job) {
      return job.Appointments;
    }
  },

  computed: {
    parentJobs() {
      const f = Object.groupBy(this.jobs.filter((v) => v.StatusId <= 50).sort((a, b) => a.StatusId > b.StatusId ? 1 : -1), ({ StatusName }) => StatusName);
      const s = [];
      Object.keys(f).forEach(element => {
        s.push({ status: element, items: f[element] })
      });
      return s;
      //return this.jobs.filter((v) => v.StatusId <= 49);
    },
  },

  watch: {},
};
</script>


<style lang="css" scoped>
.hover-card {
  margin-left: 5px !important;
  margin-right: 5px !important;
  margin-top: 0px !important;
  margin-bottom: 4px !important;
  padding: 0px !important;
}

.hover-title {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-size: 100%;
}

.status-title {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-size: 100%;
}

.hover-text {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-size: 90%;
}

.hover-subtitle {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 90%;
}

.hover-card:hover {
  filter: brightness(95%);
}

.row-pointer>>>tbody tr :hover {
  cursor: pointer;
}

.mobile {
  color: #333;
}

@media screen and (max-width: 768px) {
  .mobile div table tbody tr {
    color: var(--v-primary-base);
    max-width: 100%;
    position: relative;
    display: block;
    height: unset;
  }

  .mobile div table tbody tr:nth-inverseitem(odd) {
    border-left: 6px solid var(--v-secondary-base);
  }

  .mobile div table tbody tr:nth-inverseitem(even) {
    border-left: 6px solid var(--v-primary-base);
  }

  .mobile div table tbody tr td {
    text-align: left;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  .mobile div table tbody tr td ul li:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }
}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-item {
  padding: 5px;
  width: 50%;
  font-weight: bold;
}

.flex-itemrow {
  padding: 5px;
  width: 100%;
  font-weight: bold;
}

.nomobile.theme--light.v-data-table>>>tbody tr {
  height: 70px;
}

.theme--light.v-data-table>>>tbody tr:nth-of-type(even) {
  background-color: var(--v-theme-light-base)
}

.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: var(--v-theme-dark-base)
}
</style>