<template>
  <v-card class="JobListItem" :flat="true" @click="e => $emit('click', e)">
    <v-container class="pa-2" style="background-color: white;" fluid>

      <!-- full top row on bigger screens -->
      <v-row v-if="!isPhoneBool" justify="space-between" no-gutters class="px-3">

        <v-col cols="auto" style="min-width: 130px;">
          <b>
            <slot name="order" />
          </b>
        </v-col>

        <v-col cols="auto" style="min-width: 130px;">
          <slot name="status" />
        </v-col>

        <v-col cols="auto" style="min-width: 160px;">
          <slot name="type" />
        </v-col>

        <v-col v-if="!isPadBool" cols="4" :class="header ? '' : 'my-n1'">
          <slot name="machines" />
        </v-col>

        <v-col cols="auto" style="min-width: 160px;">
          <slot name="priority" />
        </v-col>

        <v-col cols="auto" style="min-width: 80px;">
          <slot name="createddate" />
        </v-col>

      </v-row>

      <!-- reduced top row for parent job propperties on smaller screens -->
      <v-row v-else no-gutters>
        <v-col cols="12">
          <v-row no-gutters justify="space-between">
            <v-col>
              <slot name="status" />
            </v-col>
            <v-col class="d-flex justify-center">
              <b>
                <slot name="order" />
              </b>
            </v-col>
            <v-col class="d-flex justify-end">
              <slot name="type" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- bottom row for machines on smaller screens -->
      <template v-if="!header && isPadBool">
        <v-divider class="my-2" style="opacity: 0.2;"></v-divider>
        <v-row no-gutters style="background-color: #00000008;" class="ma-n2" :class="header ? 'px-2 py-1' : 'py-1'">
          <v-col>
            <slot name="machines" />
          </v-col>
        </v-row>
      </template>

    </v-container>

    <v-divider :style="'border-color: rgb(0 0 0 /' + (header ? 40 : 10) + '%);'" />
  </v-card>
</template>


<script>
export default {
  name: "JobListItemSlotTemplate",
  props: {
    header: Boolean,
  },
  components: {},

  data() {
    return {
    };
  },

  mounted() { },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped>
.JobListItem div {
  text-transform: capitalize !important;
  /* This just makes sure the first letter of each header is capitalized */
}
</style>