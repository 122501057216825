<template>
  <v-container>
    <v-card>
      <v-card-title>{{ $t("text.credentials") }}</v-card-title>
      <v-divider />
      <v-card-text>
        <v-text-field :label="$t('text.username')" v-model="displayName" readonly>
        </v-text-field>
        <v-text-field :label="$t('text.email')" v-model="email" readonly>
        </v-text-field>
      </v-card-text>

      <v-card-actions>
        <ResetPasswordDialog>
          <template v-slot:activator>
            <v-btn color="warning">
              {{ $t('text.change_password') }}
              <v-icon class="ml-2">mdi-form-textbox-password</v-icon>
            </v-btn>
          </template>
        </ResetPasswordDialog>
        <v-spacer></v-spacer>
        <v-btn color="error" dark @click="$store.dispatch('logout')">
          {{ $t("text.logout") }}
          <v-icon class="ml-2">mdi-logout-variant</v-icon>
        </v-btn>

      </v-card-actions>

      <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
    </v-card>
  </v-container>
</template>


<script>
import ResetPasswordDialog from "@/components/Dialogs/ResetPasswordDialog.vue";

export default {
  name: "CustomerAccount",
  components: {
    ResetPasswordDialog,
  },

  data() {
    return {
      email: "",
      displayName: "",
      snackbarText: "",
      snackbar: false,
    };
  },

  mounted() {
    this.email = this.userInfo.Email;
    this.displayName = this.userInfo.Name;
  },

  methods: {},

  computed: {
    userInfo() {
      if (this.$store.state.userInfo) return this.$store.state.userInfo;
      return { Customer: {}, Provider: {} };
    },
  },

  watch: {
    userInfo(val) {
      this.email = val.Email;
      this.displayName = val.Name;
    },
  },
};
</script>