<template>
  <div v-if="isLoggedIn && $store.state.userInfo">

    <!-- main nav menu -->
    <SideBar v-if="!isPadBool" />
    <BottomBar v-else />

    <!--
    <v-app-bar app color="white">
      <v-card-title class="px-0" style="white-space: nowrap;
                           overflow: hidden;
                           text-overflow: ellipsis;">
        {{ pageTitle }}
      </v-card-title>
      <v-spacer />
      <GlobalSearchField />
      <v-spacer />
     <ShoppingCartButton /> 
      <div class="d-flex ml-4 mr-n4 my-n1"
        style="box-shadow: inset 0 0 5px #cccccc !important; background-color: #f4f4f4;">
        <v-card width="300" color="transparent" flat tile>
          <DirectorySwitcher />
        </v-card>
        <AccountButton />
      </div>
    </v-app-bar>
  -->

    <v-main>
      <div :style="'min-height: calc(100vh - 87px); background-color: #f5f5f6;'">
        <router-view></router-view>
      </div>

      <!-- Footer -->
      <Footer v-if="!dontShowFooter" />
    </v-main>
  </div>

  <!-- for between login and userinfo established -->
  <loading-box v-else-if="!$store.state.userInfo && $store.state.token" />

  <!-- for login -->
  <LoginPage v-else>
  </LoginPage>
</template>


<script>
import LoginPage from "@/views/Customer/LoginPage.vue";
import LoadingBox from "@/components/LoadingBox.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import BottomBar from "@/components/BottomBar.vue";

export default {
  name: "CustomerPortalWrapper",
  components: {
    LoginPage,
    "loading-box": LoadingBox,
    Footer,
    SideBar,
    BottomBar,
  },

  data() {
    return {
      loggedin: false,
      isMobile: false,
    };
  },

  mounted() {
    this.updateUserInfo();
    this.onResize();
    this.setFavicon("faviconCustomerPortal.ico"); //method from main.js
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    this.setFavicon("favicon.ico"); //method from main.js
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 900;
    },

    updateUserInfo() {
      if (this.$store.state.token && !this.$store.state.userinfo) {
        this.$store.dispatch("getUserInfo");
      }
    },
  },

  computed: {
    userInfo() {
      if (this.$store.state.userInfo) return this.$store.state.userInfo;
      return { Customer: {}, Provider: {} };
    },
    isLoggedIn() {
      return this.$store.state.token != null;
    },

    pageTitle() {
      if (this.$route.params.Machine?.DisplayName) {
        return this.$route.params.Machine?.DisplayName;
      } else if (this.$route.meta?.displayName) {
        return this.$route.meta.displayName;
      } else {
        return "";
      }
    },

    dontShowFooter() {
      var banList = ["MachineList", "JobList"];
      return banList.includes(this.$route.name);
    },
  },

  watch: {
    '$route.name': function () {
      window.scrollTo(0, 0); //reset scroll position when swapping pages as they would otherwise load at the same scroll position as the previous page
      console.log('test');
    },
  },
};
</script>


<style scoped>
.header-class {
  font-size: 1.2em;
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style lang="scss">
.borderless-input>>>.v-input__slot::before,
.borderless-input .v-input__control .v-input__slot::before {
  border-style: none !important;
}
</style>