<template>
  <div class="d-flex flex-column justify-center"
       style="height: 44px;">
    <div class="label">{{label}}</div>
    <div class="text"
         :class="color + '--text'">{{text}}</div>
  </div>
</template>


<script>
export default {
  name: "ImitationInput",
  props: {
    label: String,
    text: String,
    color: String,
  },
  components: {},

  data() {
    return {};
  },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped>
.label {
  margin-top: 4px;
  font-size: 12px;
  color: rgb(147, 147, 147);
}
.text {
  font-size: 16px;
}
</style>