<template>
  <v-container fluid fill-height class="loginOverlay ma-auto">
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm5 md3>
        <LoginForm />
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
import LoginForm from "@/components/Forms/LoginForm.vue";

export default {
  name: "LoginPage",
  components: {
    LoginForm,
  },

  data() {
    return {

    };
  },

  mounted() { },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang="scss"></style>