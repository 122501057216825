<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    :disabled="!editable"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        :readonly="!editable"
        v-model="dateFormatted"
        hide-details
        append-icon="mdi-calendar"
        v-bind="attrs"
        @blur="date = parseDate(dateFormatted)"
        v-on="on"
        :solo="!editable"
        :flat="!editable"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title @input="menu = false" @change="changedDate"></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: "EditableLabel",
  data() {
    return {
      menu: false,
      date: new Date(this.value).toISOString().substr(0, 10),
      dateFormatted: this.formatDate(
        new Date(this.value).toISOString().substr(0, 10)
      ),
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  methods: {
    changedDate(date) {
      this.$emit("change", date);
    },
    formatDate(date) {
      if (!date) return null;
      if (date == "1970-01-01") return "";
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  props: {
    value: {
      type: String,
      default() {
        return null;
      },
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>