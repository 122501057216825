<template>
    <v-card v-if="!asButton" link tile flat color="transparent" class="sidenav card" :to="route">
        <v-container>
            <v-row justify="center" class="mt-2" no-gutters>
                <v-badge :color="countColor" :content="count" :value="!!count" :offset-y="8" :offset-x="4">
                    <v-icon size="24">{{ icon }}</v-icon>
                </v-badge>
            </v-row>
            <v-row justify="center" class="my-2 px-2 text-center" no-gutters v-text="title">
            </v-row>
        </v-container>
    </v-card>

    <v-btn v-else :to="route" class="bottombar_btn">
        <span>{{ title }}</span>
        <v-badge :color="countColor" :content="count" :value="!!count" :offset-y="12" :offset-x="4">
            <v-icon>{{ icon }}</v-icon>
        </v-badge>
    </v-btn>
</template>


<script>
export default {
    name: 'NavTile',
    props: {
        title: String,
        icon: String,
        route: [Object, String],
        count: [Number, String],
        countColor: {
            type: String,
            default: 'red',
        },
        asButton: Boolean,
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped>
/*
.sidenav.card {
  border-bottom: 1px solid #e0e0e0;
}
*/

.sidenav.card,
.sidenav.card i.v-icon {
    opacity: 0.9;
}

.sidenav.card.router-link-active,
.sidenav.card.router-link-active i.v-icon {
    opacity: 1;
    color: #3f51b5;
}

.sidenav.card.router-link-active::before,
.sidenav.card.router-link-active::after {
    content: "";
    position: absolute;
    z-index: 10;
    height: 100%;
    background-color: #3f51b5;
}

.sidenav.card.router-link-active::before {
    width: 7px;
    opacity: 1;
    border-radius: 2px;
}

.sidenav.card.router-link-active::after {
    width: 100%;
    top: 0;
    opacity: 0.08;
}


.bottombar_btn {
    color: var(--v-tertiary-base) !important;
}
</style>