export default {
  name: 'FolderMixin',
  data() {
    return {
      DocumentTypes: {
        LINK: 1,
        FORM: 2,
        PARTDRAWING: 3,
      }
    };
  },

  mounted() { },

  methods: {
    folderName(folder) {
      if (folder.InheritedFromType == 'MachineJobHistory') {
        return this.$t('text.files_for_specific_orders');
      }
      else if (folder.InheritedFromType == 'Job') {
        return this.$t('text.order') + ' ' + folder.InheritedFromName;
      }
      else {
        return folder.InheritedFromName;
      }
    },

    folderDescription(folder) {
      if (folder.InheritedFromType == 'Supplier') {
        return this.$t('text.supplier_info');
      }
      else if (folder.InheritedFromType == 'MachineModel') {
        return this.$t('text.product_info');
      }
      else if (folder.InheritedFromType == 'MachineJobHistory') {
        return this.$t('text.work_documentation');
      }
      else {
        return null;
      }
    },

    countNestedFiles(folder) {
      var count = folder?.Files?.length ?? 0;
      var folderArr = Object.assign([], folder?.Folders);
      while (folderArr.length) {
        var current = folderArr.shift();
        var copy = Object.assign([], current.Folders)
        folderArr.push(...copy);
        count += current.Files ? current.Files.length : 0;
      }
      return count;
    },

    countNestedFilesNotThisFolder(folder) {
      var count = 0;
      var folderArr = Object.assign([], folder.Folders);
      while (folderArr.length) {
        var current = folderArr.shift();
        var copy = Object.assign([], current.Folders)
        folderArr.push(...copy);
        count += current.Files ? current.Files.length : 0;
      }
      return count;
    },

    unNestFiles(folder) {
      var fileArr = Object.assign([], folder.Files);
      var folderArr = Object.assign([], folder.Folders);
      while (folderArr.length) {
        var current = folderArr.shift();
        var folderArrCopy = Object.assign([], current.Folders)
        folderArr.push(...folderArrCopy);
        var fileArrCopy = Object.assign([], current.Files);
        fileArr.push(...fileArrCopy);
      }
      return fileArr;
    },

    filterFileArrByType(fileArr, typeId) {
      if (!fileArr || !fileArr.length) {
        return []
      }
      else if (!typeId) {
        return fileArr;
      } else if (typeId == 'Media') {
        return fileArr.filter(x => x.IsMedia);
      }
      else {
        return fileArr.filter(x => (x.TypeId == typeId) && !x.IsMedia);
      }
    },

    filterFileArrBySearch(fileArr, keyword) {
      if (!keyword) {
        return fileArr;
      }
      else {
        return fileArr.filter(x => JSON.stringify(x).toLocaleLowerCase().includes(keyword.toLocaleLowerCase()));
      }
    },

    countFilesByType(typeId, folder) {
      var allFiles = this.unNestFiles(folder);
      return Object.assign([], this.filterFileArrByType(allFiles, typeId)).length;
    },

    filterFiles(searchword, typeId, folder) {
      folder.Files = this.filterFileArrByType(folder.Files, typeId);
      folder.Files = this.filterFileArrBySearch(folder.Files, searchword);
      folder.Folders.forEach(subfolder => {
        this.filterFiles(searchword, typeId, subfolder)
      });
      return folder;
    },

    chipOptions(folder) {
      var options = [
        { value: null, text: this.$t('text.all') + ' (' + this.countFilesByType(null, folder) + ')' },
      ];
      var mediaCount = this.countFilesByType('Media', folder);
      var manualsCount = this.countFilesByType(this.DocumentTypes.LINK, folder);
      var formsCount = this.countFilesByType(this.DocumentTypes.FORM, folder);
      var partDrawingCount = this.countFilesByType(this.DocumentTypes.PARTDRAWING, folder);
      //var linkCount = this.countFilesByType(this.DocumentTypes.LINK, folder);
      //console.log('mediaCount', mediaCount, 'manualsCount', manualsCount, 'formsCount', formsCount, 'partDrawingCount', partDrawingCount, /*'linkCount', linkCount*/);
      if (mediaCount) {
        options.push({ value: 'Media', text: this.$t('text.media') + ' (' + mediaCount + ')' },)
      }
      if (manualsCount) {
        options.push({ value: this.DocumentTypes.LINK, text: this.$t('text.documents') + ' (' + manualsCount + ')' },)
      }
      if (formsCount) {
        options.push({ value: this.DocumentTypes.FORM, text: this.$t('text.forms') + ' (' + formsCount + ')' },)
      }
      if (partDrawingCount) {
        options.push({ value: this.DocumentTypes.PARTDRAWING, text: this.$t('text.exploded_view') + ' (' + partDrawingCount + ')' },)
      }
      /*
      if (linkCount) {
        options.push({ value: (this.DocumentTypes.LINK + 5), text: 'Other (' + linkCount + ')' },)
      }
      */
      return options;
    },
  },

  computed: {},

  watch: {},
}