<template>
  <v-card flat style="max-width: 330px;">
    <v-img src="/api2/Report/logo" max-height="90" contain position="center" />
    <h3 class="text-center mt-2">{{ $t("customer.portal.title") }}</h3>

    <v-card-text class="mt-4 px-5">
      <SemdResetContent v-if="mode == 'send'" />
      <OTPContent v-else-if="mode == 'otp'" />
      <SetPWContent v-else-if="mode == 'set'" />
      <LoginContent v-else />
    </v-card-text>
  </v-card>
</template>


<script>
import LoginContent from '@/components/Forms/LoginContent.vue';
import SemdResetContent from '@/components/Forms/SendResetContent.vue';
import OTPContent from '@/components/Forms/OTPContent.vue';
import SetPWContent from '@/components/Forms/SetPWContent.vue';

export default {
  name: "LoginForm",
  components: {
    LoginContent,
    SemdResetContent,
    OTPContent,
    SetPWContent,
  },

  data() {
    return {
    };
  },

  mounted() {
    this.mode = null;
  },

  methods: {},

  computed: {
    mode: {
      get() {
        return this.getQueryParam("mode", "");
      },
      set(update) {
        this.setQueryParam("mode", update);
      },
    },
  },

  watch: {
    mode: function (val) {
      if (val != 'otp' && val != 'set') {
        this.setQueryParam("otp", undefined);
      }
    }
  },
};
</script>


<style lang="scss"></style>