<template>
  <v-dialog v-model="showDialog" max-width="1000" :fullscreen="isPhoneBool">
    <v-card v-if="loading || error" height="75vh">
      <CenteredLoading :displayBool="true" :completedBool="!loading" :successBool="!error" :displayText="''">
        <!-- $t('text.loading') -->
      </CenteredLoading>
    </v-card>
    <v-card v-else-if="Job">
      <v-card-title>
        {{ Job && Job.DisplayName }}
        <v-spacer></v-spacer>
        <!-- 
        <v-btn color="primary" outlined small class="pl-1 pr-2" style="text-transform: none;">
          <v-icon size="18" class="mr-1">mdi-paperclip</v-icon>
          {{ $t("text.service_report") }}
        </v-btn>
        -->
        <v-btn color="error" icon class="mr-n2" @click="hide()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider v-if="isPhoneBool"></v-divider>
      <v-card-text class="px-3">
        <v-row no-gutters>
          <v-col :cols="isPhoneBool ? 12 : 5">
            <v-container>
              <v-row no-gutters align="center" justify="space-between">
                <v-col class="py-2">
                  {{ $t('text.ordernumber') }}:
                </v-col>
                <v-col class="py-2">
                  <b style="color: black;">
                    {{ (Job.JobTypeId != 3 ? Job.OrderId : ('S' + Job.Id)) }}
                  </b>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="space-between">
                <v-col class="py-2">
                  {{ $t("text.status") }}:
                </v-col>
                <v-col class="py-2">
                  <JobStatusChip :statusId="Job.StatusId" :statusName="Job.StatusName">
                  </JobStatusChip>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="space-between">
                <v-col class="py-2">
                  {{ $t("text.type") }}:
                </v-col>
                <v-col class="py-2">
                  <JobTypeChip :typeId="Job.JobTypeId" :typeName="Job.JobTypeName">
                  </JobTypeChip>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="space-between">
                <v-col class="py-2">
                  {{ $t("text.created_date") }}:
                </v-col>
                <v-col class="py-2">
                  {{ new Date(Job.CreatedDate).toLocaleString() }}
                </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="space-between">
                <v-col class="py-2">
                  {{ $t("text.priority") }}:
                </v-col>
                <v-col class="py-2">
                  {{ Job.JobPriority.Name }}
                </v-col>
              </v-row>

              <v-row no-gutters align="center" justify="space-between">
                <v-col class="py-2">
                  {{ $t("text.scheduledfor") }}:
                </v-col>
                <v-col class="py-2">
                  <div v-if="Job.Appointments && Job.Appointments.length > 0">
                    <span v-for="(app, index) in Job.Appointments" :key="index">
                      {{ (index > 0 ? " & " : "") + new Date(app.AppointmentDate).toLocaleString() }}
                    </span>
                  </div>
                  <div v-else>{{ $t("text.not_scheduled") }}</div>
                </v-col>
              </v-row>

              <v-row
                v-if="Job.CustomerDeliveryAdressStreet || Job.CustomerDeliveryAdressZip || Job.CustomerDeliveryAdressCity || Job.CustomerDeliveryAdressCountry">
                <v-col>
                  <v-card flat outlined class="ml-n3">
                    <v-card-text class="pa-3">
                      <div>
                        <b>{{ $t("text.delivery_address") }}</b>
                      </div>
                      <div>{{ Job.CustomerDeliveryAdressStreet }}</div>
                      <div>{{ Job.CustomerDeliveryAdressZip }} {{ Job.CustomerDeliveryAdressCity }}</div>
                      <div>{{ Job.CustomerDeliveryAdressCountry }}</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-col>

          <v-col v-if="Job.InverseParent" :cols="isPhoneBool ? 12 : 7">
            <v-row v-if="Job.InverseParent.length > 1" no-gutters align="center">
              <v-col cols="auto" class="pr-3">
                <b>Machines ({{ Job.InverseParent.length }})</b>
              </v-col>
              <v-divider />
            </v-row>
            <div style="overflow-y: auto; height: 60vh; border-left: 1px solid #e0e0e0;"
              :style="isPhoneBool ? 'border-left: none;' : ''">
              <div v-for="(machineJob, index) in Job.InverseParent" :key="machineJob.Id">
                <v-container class="pr-0">
                  <MachineListItem :item="machineJob.Machine" showOpenBtn @click.stop="openMachine(machineJob.Machine)">
                  </MachineListItem>
                  <div>
                    <small>
                      <b>
                        {{ $t("text.error_description") }}
                      </b>
                    </small>
                  </div>
                  <div style="font-size: 13px; max-height: 187px; overflow-y: auto; white-space: pre-wrap;">
                    {{ machineJob.CustomerComment }}
                  </div>
                  <template v-if="Job.StatusId > 49">
                    <div>
                      <small>
                        <b>
                          {{ $t('text.invocie_comment') }}
                        </b>
                      </small>
                    </div>
                    <div style="font-size: 13px; max-height: 187px; overflow-y: auto; white-space: pre-wrap;">
                      {{ machineJob.InvoiceComment || 'Not provided' }}
                    </div>
                  </template>
                </v-container>
                <v-divider v-if="index < (Job.InverseParent.length - 1)"></v-divider>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="hide" outlined color="accent darken-2">
          {{ $t("text.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import http from "@/api/client";
import MachineListItem from "@/components/ListItems/MachineListItem.vue";
import JobStatusChip from '@/components/JobStatusChip.vue';
import JobTypeChip from '@/components/JobTypeChip.vue';
import CenteredLoading from "@/components/CenteredLoading.vue";

export default {
  name: "JobDialog",
  components: {
    MachineListItem,
    JobStatusChip,
    JobTypeChip,
    CenteredLoading,
  },

  data() {
    return {
      showDialog: false,
      loading: false,
      error: null,
      Job: null,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      var jobId = this.$route.params.jobId;
      if (jobId) {
        this.showDialog = true;
        this.loading = true;
        this.error = null;
        await http()
          .get("api2/customer/jobs/" + jobId)
          .then((response) => {
            this.Job = response.data;
          })
          .catch((e) => {
            this.error = e;
          });
        this.loading = false;
      }
    },

    openMachine(machine) {
      this.$router.push({ name: 'ViewMachine', params: { id: machine.Id } });
    },

    hide() {
      var currentParams = Object.assign({}, this.$route.params);
      currentParams.jobId = undefined;
      this.$router.push({ params: currentParams });
    },
  },

  computed: {},

  watch: {
    "$route.params.jobId"(val) {
      val ? this.init() : (this.showDialog = false);
    },

    showDialog: function (val) {
      if (!val && this.$route.params.jobId) {
        this.hide();
      }
    },
  },
};
</script>