<!-- Simple async dialog box (ETA) 

    Usage: 
        <confirm-dialog ref="ConfirmDialog"></confirm-dialog>
    Method 1:
    async method() {
        var result = await this.$refs.ConfirmDialog.show({
            title: "Delete",
            message: "Are you sure?",
            okButton: "Delete",
            cancelButton: "Cancel",
        })
        if (result) {
            // user confirmed
        }
    }
-->
<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="cancel-btn" outlined color="error" @click="_cancel">
          {{ cancelButton }}
        </v-btn>
        <v-btn class="cancel-btn" dark color="tertiary" @click="_confirm">
          {{ okButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>


export default {
  name: "ConfirmDialog",

  data: () => ({
    dialog: false,
    title: undefined,
    message: undefined,
    okButton: "Ok", // Text for confirm button
    cancelButton: "Cancel", // text for cancel button

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    // used by main component reference
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.dialog = true;
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _confirm() {
      this.dialog = false;
      this.resolvePromise(true);
    },
    _cancel() {
      this.dialog = false;
      this.resolvePromise(false);
    },
  },
};
</script>