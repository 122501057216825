<template>
  <v-dialog
    v-if="!Document"
    v-model="value"
    max-width="300"
    @click:outside="hide"
  >
    <v-card>
      <v-card-title>{{$t("text.loading")}}...</v-card-title>
      <v-card-text>
        <v-progress-linear indeterminate></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="value" max-width="1000" v-else persistent fullscreen>
    <v-card v-if="Document">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ Document.Name }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="hide">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0 ma-0">
        <img
          :src="Document.PartDrawingImage"
          usemap="#documentpartdrawing"
          style="max-width: 650px"
        />
      </v-card-text>
      <div v-html="correctPath(Document.PartDrawingImageMap)" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="hide"
          outlined
          color="accent darken-2"
          style="position: fixed; bottom: 20px; right: 20px"
          >{{ $t("text.back") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import http from "@/api/client";

export default {
  name: "CustomerDocumentInfoDialog",
  
  data() {
    return {
      loading: false,
      value: true,
      Document: null,
      part: null,
      machineId: this.$route.params.id,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    correctPath(body) {
      return body.replaceAll(
        'href="',
        'href="#/customer/manage/' + this.machineId + "/parts/"
      );
    },

    init() {
      this.part = this.$route.params.documentid;
      if (this.part) {
        this.loading = true;
        this.machineId = this.$route.params.id;
        http()
          .get(
            "api2/customer/documents/partdrawing/" +
              this.machineId +
              "/" +
              this.part
          )
          .then((response) => {
            this.Document = response.data;
            this.loading = false;
          });
      }
    },

    hide() {
      this.$router.back();
    },
  },
  
  watch: {
    $route() {
      this.init();
    },
  },
};
</script>