<template>
  <UserListItemSlotTemplate :header="header" :class="!header && !item.Active ? 'deactivated' : ''"
    @click="e => $emit('click', e)">

    <template v-slot:avatar>
      <template v-if="header">
        <v-card width="25" class="mr-3"></v-card>
      </template>
      <Avatar v-else :fullname="item.Name" :small="true" :color="avatarcolor" class="mr-3" />
    </template>

    <template v-slot:name>
      {{ header ? 'Name' : item.Name }}
    </template>

    <template v-slot:email>
      <template v-if="header">Email</template>
      <div v-else-if="item.EmailAdress">
        {{ item.EmailAdress }}
      </div>
    </template>

    <template v-slot:usertype>
      <template v-if="header">Role</template>
      <div v-else-if="item.UserTypeId" :style="'color: ' + typecolor + ';'">
        {{ item.UserTypeId == 4 ? 'Moderator' : 'User' }}
      </div>
    </template>

    <template v-slot:actions>
      <template v-if="header">
        <v-card flat class="ml-2">
          Active
        </v-card>
      </template>
      <div v-else-if="me.UserTypeId != 6 || me.Id == item.Id" icon class="ml-2">
        <v-switch v-model="item.Active" class="pa-0 ma-0" hide-details dense @change="$emit('toggleActive', item)">
        </v-switch>
      </div>
      <!--
        <v-btn v-else icon class="ml-3">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
        -->
    </template>

  </UserListItemSlotTemplate>
</template>


<script>
import UserListItemSlotTemplate from '@/components/ListItems/UserListItemSlotTemplate.vue';
import Avatar from '@/components/Cards/Avatar.vue';

export default {
  name: "UserListItem",
  props: {
    header: Boolean,
    item: Object,
    me: Object,
  },
  components: {
    UserListItemSlotTemplate,
    Avatar,
  },

  data() {
    return {
    };
  },

  mounted() { },

  methods: {},

  computed: {
    typecolor() {
      if (this.header || !this.item.Active) {
        return 'grey'
      }
      else if (this.item.UserTypeId == 4) {
        return '#08a7a0';
      }
      else if (this.item.UserTypeId == 6) {
        return '#018500';
      }
      else {
        return 'primary';
      }
    },

    avatarcolor() {
      if (this.me.Id == this.item.Id) {
        return 'red';
      }
      else {
        return this.typecolor;
      }
    }
  },

  watch: {},
};
</script>

<style></style>