<template>
  <v-card class="rounded-0 ContactListItem" :flat="true"> <!-- @click="e => $emit('click', e)" -->
    <v-container class="py-2 px-0" fluid>
      <v-row justify="space-between" align="center" no-gutters class="px-3">

        <v-col cols="auto">
          <slot name="avatar" />
        </v-col>

        <v-col cols="0">
          <v-row no-gutters>

            <v-col cols="6">
              <div v-if="!!$slots.name" no-gutters class="py-1 px-1 nowraptext">
                <slot name="name" />
              </div>
              <div v-if="!!$slots.title" no-gutters class="py-1 px-1 nowraptext" style="opacity: 0.7;">
                <slot name="title" />
              </div>
            </v-col>

            <v-col cols="6">
              <div v-if="!!$slots.email" no-gutters class="py-1 px-1 nowraptext">
                <slot name="email" />
              </div>
              <div v-if="!!$slots.mobile" no-gutters class="py-1 px-1 nowraptext" style="opacity: 0.8;">
                <slot name="mobile" />
              </div>
            </v-col>

          </v-row>
        </v-col>

        <v-col cols="auto" style="width: 50px;">
          <slot name="actions" />
        </v-col>

      </v-row>
    </v-container>

    <v-divider :style="'border-color: rgb(0 0 0 /' + (header ? 40 : 10) + '%);'" />
  </v-card>
</template>


<script>
export default {
  name: "ContactListItemSlotTemplate",
  props: {
    header: Boolean,
  },
  components: {},

  data() {
    return {
    };
  },

  mounted() { },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped>
.ContactListItem.deactivated {
  background-color: rgb(243, 243, 243);
}

.ContactListItem:not(.deactivated) {
  background-color: white;
}

.nowraptext {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>