<template>
  <div>
    <v-card style="position: fixed; 
                   width: 100%;
                   z-index: 6; 
                   background-color: white; 
                   box-shadow: 0px 4px 2px -2px rgb(0 0 0 / 20%)" class="rounded-0">

      <v-card v-if="loading" class="rounded-0" height="117" flat>
        <v-container fluid class="pa-0">
          <v-row no-gutters>
            <v-col cols="auto">
              <v-skeleton-loader type="image" width="117" height="117">
              </v-skeleton-loader>
            </v-col>
            <v-col cols="0">
              <v-container fluid class="pa-0">
                <v-row no-gutters>
                  <v-col>
                    <v-skeleton-loader type="list-item-two-line" />
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-4">
                  <v-col>
                    <v-skeleton-loader type="table-row" />
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-container v-else-if="machine" class="pa-0" fluid>
        <v-row no-gutters>
          <v-col cols="auto" v-if="!isPhoneBool">
            <MachineModelImage :size="117"
              :relativePath="machine && machine.MachineModel && machine.MachineModel.ModelImage">
            </MachineModelImage>
          </v-col>
          <v-col cols="0">
            <v-container class="pa-0" fluid>

              <v-row class="mx-0" no-gutters v-if="!isPhoneBool">
                <v-col v-if="machine && machine.MachineType && machine.MachineType.Name" cols="auto" class="pa-3">
                  <ImitationInput :label="$t('text.type')" :text="machine.MachineType.Name">
                  </ImitationInput>
                </v-col>
                <v-col v-if="machine && machine.Supplier && machine.Supplier.Name" cols="auto" class="pa-3">
                  <ImitationInput :label="$t('text.supplier')" :text="machine.Supplier.Name">
                  </ImitationInput>
                </v-col>
                <v-col v-if="machine && machine.MachineModel && machine.MachineModel.Name" cols="auto" class="pa-3">
                  <ImitationInput :label="$t('text.model')" :text="machine.MachineModel.Name">
                  </ImitationInput>
                </v-col>
                <v-col v-if="machine && machine.Name" cols="auto" class="pa-3">
                  <ImitationInput :label="$t('text.location')" :text="machine.Name" :color="'text--darken-3 primary'">
                  </ImitationInput>
                </v-col>
                <v-col v-if="machine && machine.SerialNumber" cols="auto" class="pa-3">
                  <ImitationInput :label="$t('text.serial_number')" :text="machine.SerialNumber"
                    :color="'text--darken-3 primary'">
                  </ImitationInput>
                </v-col>
                <v-col v-if="machine && machine.Qrcode" cols="auto" class="pa-3">
                  <ImitationInput :label="$t('text.qr_code')" :text="machine.Qrcode" :color="'text--darken-3 primary'">
                  </ImitationInput>
                </v-col>
              </v-row>
              <v-row v-else no-gutters class="pa-3">
                <MachineListItem :item="machine">
                </MachineListItem>
              </v-row>

              <v-row style="border-top: 1px solid #cfcfcf;" class="mx-0" no-gutters>
                <v-tabs v-model="tab">
                  <v-tab :to="{ name: 'GeneralPanel', params: { Machine: machine } }">
                    {{ $t('text.general') }}
                  </v-tab>

                  <v-tab :to="{ name: 'ServicePanel', params: { Machine: machine } }">
                    {{ $t('text.Service') }}
                  </v-tab>

                  <v-tooltip bottom :disabled="!!machine.MachineModel" color="orange">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="d-flex align-stretch">
                        <v-tab :disabled="!machine.MachineModel"
                          :to="{ name: 'PartsPanel', params: { Machine: machine } }">
                          {{ $t('text.Parts') }}
                        </v-tab>
                      </div>
                    </template>
                    <span>{{ $t('text.none_available') }}</span>
                  </v-tooltip>

                  <!-- 
                  <v-tab :to="{ name: 'ResourcesPanel', params: { Machine: machine } }">
                    {{ $t('text.resources') }}
                  </v-tab>
                  -->

                  <v-tooltip bottom :disabled="hasManuals" color="orange">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="d-flex align-stretch">
                        <v-tab :disabled="!hasManuals" :to="{ name: 'ManualsPanel', params: { Machine: machine } }">
                          {{ $t('text.manuals') }}
                        </v-tab>
                      </div>
                    </template>
                    <span>{{ $t('text.none_available') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom :disabled="hasPartDrawings" color="orange">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="d-flex align-stretch">
                        <v-tab :disabled="!hasPartDrawings"
                          :to="{ name: 'PartDocumentPanel', params: { Machine: machine } }">
                          {{ $t('text.exploded_view') }}
                        </v-tab>
                      </div>
                    </template>
                    <span>{{ $t('text.none_available') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom :disabled="hasForms" color="orange">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="d-flex align-stretch">
                        <v-tab :disabled="!hasForms" :to="{ name: 'FormsPanel', params: { Machine: machine } }">
                          {{ $t('text.forms') }}
                        </v-tab>
                      </div>
                    </template>
                    <span>{{ $t('text.none_available') }}</span>
                  </v-tooltip>

                </v-tabs>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div class="pt-11 mb-2"></div>
    <router-view @updateMachine="updateMachineRoute" class="pt-16" :resources="resources">
    </router-view>
  </div>
</template>


<script>
import http from "@/api/client";
import MachineModelImage from "@/components/Cards/MachineModelImage.vue";
import ImitationInput from "@/components/Inputs/ImitationInput.vue";
import MachineListItem from "@/components/ListItems/MachineListItem.vue";

export default {
  name: "ViewMachineWrapper",
  components: {
    MachineModelImage,
    ImitationInput,
    MachineListItem,
  },

  data() {
    return {
      MachineServiceHistories: null,
      overview: null,
      tab: 0,
      loading: false,
      resources: null,
    };
  },

  mounted() {
    this.getMachine();
  },

  methods: {
    updateMachineRoute() {
      this.$router.replace({
        name: this.$route.name,
        params: {
          Machine: this.machine,
        },
        query: {
          q: this.searchQuery,
          t: new Date().getTime(),
        },
      });
    },

    getMachine() {
      const machineId = this.$route.params.id;
      if (!machineId) return;
      this.loading = true;
      http()
        .get("api2/customer/machines/overview/" + machineId)
        .then((response) => {
          this.overview = response.data;
          //console.log(this.overview);
          this.loading = false;
          this.updateMachineRoute();
        });
    },

    getResourceList(machineId) {
      if (machineId) {
        this.loading = true;
        http()
          .get("api2/customer/documents/resources/" + machineId)
          .then((response) => {
            this.resources = response.data;
            this.loading = false;
          });
      }
    },
  },

  computed: {
    machine() {
      if (this.overview) return this.overview.Machine;
      return false;
    },

    hasManuals() {
      return this.overview && this.overview.DocumentManualsCount > 0;
    },

    hasPartDrawings() {
      return this.overview && this.overview.DocumentPartDrawingCount > 0;
    },

    hasForms() {
      return this.overview && this.overview.DocumentFormsCount > 0;
    },
  },

  watch: {
    $route() {
      if (this.machine && this.$route.params.id != this.machine.Id) {
        this.getMachine();
      }
    },
    machine: {
      handler() {
        if (this.editmode) {
          this.dirty = true;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  opacity: 1 !important;
}
</style>