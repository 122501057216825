
<template>
  <v-avatar :color="color"
            :size="backroundsize">
    <span :class="'white--text text-' + textsize + ' text-uppercase'">{{avatarText}}</span>
  </v-avatar>
</template>

<script>
export default {
  name: "Avatar",
  props: ["fullname", "color", "small", "large"],

  data() {
    return {};
  },

  methods: {},

  computed: {
    avatarText() {
      if(this.fullname){
      var firstLetters = this.fullname.match(/\b(\w)/g);
      var acronym = firstLetters.join("");
      var avatarText = "";
      if (acronym.length < 2) {
        avatarText = acronym + this.fullname[this.fullname.length - 1];
      } else if (acronym.length === 2) {
        avatarText = acronym;
      } else {
        avatarText = acronym[0] + acronym.slice(-1);
      }
      return avatarText;
      }
      else{
        return '??'
      }
    },

    backroundsize() {
      if (this.small) {
        return 25;
      } else if (this.large) {
        return 80;
      } else {
        return 38;
      }
    },

    textsize() {
      if (this.small) {
        return "h7";
      } else if (this.large) {
        return "h3";
      } else {
        return "h6";
      }
    },
  },

  watch: {},
};
</script>

