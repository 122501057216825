<template>
  <v-bottom-navigation app width="100vw">
    <!-- 
    <NavTile :title="$t('text.home')" icon="mdi-home" :route="{ name: 'FrontPage' }" asButton>
    </NavTile>
    -->
    <!-- 
    <NavTile :title="'Shop'" icon="mdi-shopping" :route="{ name: 'Shop' }" :count="shoppingCartCount" asButton>
    </NavTile>
    -->
    <NavTile :title="$t('route.Machines')" icon="mdi-washing-machine" :route="{ name: 'Machines' }" asButton>
    </NavTile>
    <NavTile :title="$t('text.shopping_cart')" icon="mdi-cart" :route="{ name: 'ShoppingCart' }"
      :count="shoppingCartCount" asButton>
    </NavTile>
    <NavTile :title="$t('text.history')" icon="mdi-history" :route="{ name: 'JobList' }" asButton>
    </NavTile>
    <template v-if="!isPhoneBool"> <!-- allow more bottom bar options on ipad sized screens -->

      <NavTile :title="$t('text.organization')" icon="mdi-domain" :route="{ name: 'Organization' }" asButton>
      </NavTile>
    </template>
    <template v-else> <!-- limit bottom bar options on phone sized screens -->
      <NavTile :title="$t('text.more')" icon="mdi-dots-horizontal" :route="{ name: 'Organization' }" asButton>
      </NavTile>
    </template>
  </v-bottom-navigation>
</template>


<script>
import NavTile from "@/components/NavTile.vue";

export default {
  name: "BottomBar",
  components: {
    NavTile,
  },

  data() {
    return {
    };
  },

  mounted() { },

  methods: {},

  computed: {
    userInfo() {
      if (this.$store.state.userInfo) return this.$store.state.userInfo;
      return { Customer: {}, Provider: {} };
    },

    shoppingCartCount() {
      return this.$store.getters.shoppingCartCount;
    },
  },

  watch: {},
};
</script>

<style lang="scss"></style>