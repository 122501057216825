
import JobDialog from "@/components/Dialogs/JobDialog.vue";
import ServiceProviderHome from "@/views/ServiceProvider/ServiceProviderHome.vue";

const routes = {
  path: "/serviceprovider",
  component: ServiceProviderHome,
  children: [
    {
      path: "",
      component: ServiceProviderHome,
    },
    {
      path: "dashboard",
      component: ServiceProviderHome,
    },
    {
      path: "job",
      component: ServiceProviderHome,
      children: [
        {
          name: "JobDialog",
          path: ":id",
          component: JobDialog,
        },
      ]
    },
  ]
};

export default routes;
