
<template>
  <v-overlay v-if="modal" :v-model="value" light>
    <v-card :loading="true" class="pa-10 ma-10" light>
      <v-card-title>Laster...</v-card-title>
    </v-card>
  </v-overlay>
  <v-container v-else justify="center" class="justify-center" fill-height fluid>
    <v-card class="pa-10 ma-10" :loading="true">
      <v-card-title>Laster...</v-card-title>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "LoadingBox",
  props: {
    modal: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      value: false,
    };
  },
  mounted() {
    if (this.modal) {
      this.value = true;
    }
  },
};
</script>