<template>
  <JobListItemSlotTemplate :header="header" @click="e => $emit('click', e)">

    <template v-slot:order>
      {{ header ? $t('text.ordernumber') : (item.JobTypeId != 3 ? item.OrderId : ('S' + item.Id)) }}
    </template>

    <template v-slot:type>
      <template v-if="header">{{ $t('text.type') }}</template>
      <JobTypeChip v-else :typeId="item.JobTypeId" :typeName="item.JobTypeName">
      </JobTypeChip>
    </template>

    <template v-slot:status>
      <template v-if="header">{{ $t('text.status') }}</template>
      <JobStatusChip v-else :statusId="item.StatusId" :statusName="item.StatusName">
      </JobStatusChip>
    </template>

    <template v-slot:machines>
      <template v-if="header">{{ $t('text.equipment') }}</template>
      <template v-else>
        <MachineListItem v-for="machineJob in item.InverseParent" :key="machineJob.Id" :item="machineJob.Machine"
          @click.stop="$emit('machineClick')">
        </MachineListItem>
      </template>
    </template>

    <template v-slot:priority>
      {{ header ? $t('text.priority') : item.JobPriority.Name }}
    </template>

    <template v-slot:createddate>
      {{ header ? $t('text.created_date') : new Date(item.CreatedDate).toLocaleDateString() }}
    </template>

  </JobListItemSlotTemplate>
</template>


<script>
import JobListItemSlotTemplate from '@/components/ListItems/JobListItemSlotTemplate.vue';
import MachineListItem from '@/components/ListItems/MachineListItem.vue';
import JobStatusChip from '@/components/JobStatusChip.vue';
import JobTypeChip from '@/components/JobTypeChip.vue';


export default {
  name: "JobListItem",
  props: {
    header: Boolean,
    item: Object,
  },
  components: {
    JobListItemSlotTemplate,
    MachineListItem,
    JobStatusChip,
    JobTypeChip,
  },

  data() {
    return {
    };
  },

  mounted() { },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped>
.JobListItem div div div div {
  text-transform: capitalize !important;
}
</style>