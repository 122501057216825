<template>
  <!--
  <v-menu offset-y
          max-width="500"
          :nudge-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-badge class="d-none d-sm-flex"
               color="warning"
               :content="shoppingCartCount"
               :value="shoppingCartCount"
               overlap>
        <v-btn outlined
               v-bind="attrs"
               v-on="on"
               class="d-none d-sm-flex">
          <v-icon v-if="!shoppingCartLoading">mdi-cart</v-icon>
          <v-progress-circular v-else
                               size="20"
                               indeterminate>
          </v-progress-circular>
        </v-btn>
      </v-badge>
    </template>
<v-card>
  <v-card-text v-for="cart in shoppingCart" :key="cart.CartId">
    <v-card-title class="header-class ma-0 pa-0">{{
      cart.Machine.DisplayName
      }}</v-card-title>
    <v-list dense class="ma-0 pa-0">
      <v-list-item v-for="(item, index) in cart.Items" :key="index">
        <v-list-item-avatar>
          <v-img v-if="item.Article && item.Article.ArticleImage" :src="item.Article.ArticleImage" max-height="50"
            max-width="50" contain @error="cartImageLoadError(item, $event)"></v-img>
          <v-img v-else max-height="50" max-width="50" contain :src="noimage" />
        </v-list-item-avatar>
        <v-list-item-title>{{
          item.Article.ArticleNumber
          }}</v-list-item-title>
        <v-list-item-subtitle>{{
          item.ArticleCount
          }}</v-list-item-subtitle>
        <v-list-item-subtitle>{{
          formatCurrency(item.ArticlePrice)
          }}</v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </v-card-text>
  <v-card-actions>
    <v-spacer />
    <v-btn outlined color="primary" :to="{ name: 'CustomerShoppingCart' }">{{ $t("cart.button.showcart") }}</v-btn>
    <v-spacer />
  </v-card-actions>
</v-card>
</v-menu>
-->

  <v-badge class="d-none d-sm-flex" color="warning" :content="shoppingCartCount" :value="shoppingCartCount" overlap>
    <v-btn :to="{ name: 'ShoppingCart' }" outlined ref="cartbtn" :color="shoppingCartCount ? 'warning' : ''"
      class="d-none d-sm-flex">
      <v-icon v-if="!shoppingCartLoading">mdi-cart</v-icon>
      <v-progress-circular v-else size="20" indeterminate>
      </v-progress-circular>
    </v-btn>
  </v-badge>

  <!--
  <v-menu v-model="menuOpen"
          offset-y
          rounded="0"
          :min-width="menuWidth"
          :max-width="menuWidth"
          :close-on-content-click="false"
          right>
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-center"
           style="height: 64px !important;"
           v-bind="attrs"
           v-on="on">
        <v-badge class="d-none d-sm-flex"
                 color="warning"
                 :content="shoppingCartCount"
                 :value="shoppingCartCount"
                 overlap>
          <v-btn outlined
                 ref="cartbtn"
                 :color="shoppingCartCount ? 'warning' : ''"
                 class="d-none d-sm-flex">
            <v-icon v-if="!shoppingCartLoading">mdi-cart</v-icon>
            <v-progress-circular v-else
                                 size="20"
                                 indeterminate>
            </v-progress-circular>
          </v-btn>
        </v-badge>
      </div>
    </template>
    <v-card class="rounded-0"
            style="overflow-y: auto;"
            :style="'max-height: calc(100vh - 64px);' + ' min-height: 150px'">
      <CartView @confirmOrder="confirmOrder()">
        <template v-slot:prepend-top>
          <v-row no-gutters
                 class="mb-3">
            <v-btn color="error"
                   text
                   @click="menuOpen = false">
              {{$t('text.clear_cart')}} <v-icon class="ml-2" size="20">mdi-delete-empty</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn color="purple"
                   text
                   :to="{ name: 'CustomerShoppingCart' }"
                   @click="menuOpen = false">
              {{$t('text.fullscreen')}} <v-icon class="ml-2">mdi-fullscreen</v-icon>
            </v-btn>
          </v-row>
        </template>
      </CartView>
    </v-card>
  </v-menu>
  -->
</template>


<script>
import noimage from "@/assets/noimage.jpg";
//import CartView from "@/views/ComponentViews/Component3.vue";

export default {
  name: "ShoppingCartButton",
  props: {},
  components: {
    //CartView,
  },

  data() {
    return {
      noimage: noimage,
      menuOpen: false,
      menuWidth: "100vw",
    };
  },

  mounted() {
    setTimeout(() => {
      this.getCartMenuWidth();
    }, 50);
  },

  methods: {
    cartImageLoadError(item /*, url*/) {
      item.Article.ArticleImage = noimage;
    },

    getCartMenuWidth() {
      var maxwidth = "100vw";
      var ref = this.$refs.cartbtn;
      if (ref) {
        var left = ref.$el.getBoundingClientRect().x;
        var leftWithScrollbarOffset = left + 16;
        maxwidth = "calc(100vw - " + leftWithScrollbarOffset + "px)";
      }
      this.menuWidth = maxwidth;
    },

    confirmOrder() { },
  },

  computed: {
    shoppingCartLoading() {
      return this.$store.state.shoppingCartLoading || false;
    },

    shoppingCart() {
      if (
        this.$store.state.shoppingCart &&
        this.$store.state.shoppingCart.length > 0
      ) {
        return this.$store.state.shoppingCart;
      }
      return [];
    },

    shoppingCartCount() {
      return this.$store.getters.shoppingCartCount;
    },
  },

  watch: {},
};
</script>


<style lang='scss' scoped></style>