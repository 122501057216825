<template>
  <div v-if="isLoggedIn && $store.state.userInfo">
    <v-navigation-drawer app v-model="drawer" width="130">

      <v-card tile flat to="/partner">
        <v-container class="pt-2 pb-0 px-0">
          <v-row justify="center" class="pb-1" no-gutters>
            <v-img src="/api2/Report/logo" height="52" max-width="110" contain :alt="userInfo.Provider.CompanyName"
              position="center">
            </v-img>
          </v-row>
          <v-row justify="center" class="py-1" style="background-color: #f8f8f8;" no-gutters>
            Partner Portal
          </v-row>
        </v-container>
      </v-card>
      <v-card tile flat color="#f0f0f0" class="text-center py-1" style="color: #7b7b7b;" href="https://www.qwips.no">
        <small>Powered by Qwips</small>
      </v-card>
      <v-divider />

      <v-list dense class="py-0">
        <v-card v-for="item in navLinks" :key="item.title" link tile flat class="sidenav card" :to="item.route">
          <v-container>
            <v-row justify="center" class="mt-2" no-gutters>
              <v-icon size="24">{{ item.icon }}</v-icon>
            </v-row>
            <v-row justify="center" class="my-2" no-gutters>
              {{ item.title }}
            </v-row>
          </v-container>
        </v-card>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app elevation="1">
      <v-card-title class="px-0" style="white-space: nowrap;
                           overflow: hidden;
                           text-overflow: ellipsis;">
        {{ pageTitle }}
      </v-card-title>
      <v-spacer />
      <AccountButton />
    </v-app-bar>

    <v-main>
      <div :style="'min-height: calc(100vh - ' + (200 + (isMobile ? 56 : 64)) + 'px); background-color: #f5f5f6;'">
        <router-view></router-view>
      </div>
    </v-main>
  </div>
  <loading-box v-else-if="!$store.state.userInfo && $store.state.token" />
  <div v-else class="fill-height">
    <login-form></login-form>
  </div>
</template>


<script>
import AccountButton from "@/components/AccountButton.vue";
import LoginForm from "@/components/Forms/LoginFormPartner.vue";
import LoadingBox from "@/components/LoadingBox.vue";

export default {
  name: "PartnerHome",
  components: {
    "login-form": LoginForm,
    "loading-box": LoadingBox,
    AccountButton,
  },

  data() {
    return {
      drawer: true,
      loggedin: false,
      navLinks: [
        {
          title: this.$t("route.Dashboard"),
          icon: "mdi-view-dashboard",
          route: "/partner/dashboard",
        },
        {
          title: this.$t("route.SupportJobs"),
          icon: "mdi-briefcase",
          route: "/partner/jobs",
        },
      ],
      isMobile: false,
    };
  },

  mounted() {
    this.updateUserInfo();
    this.onResize();
    this.setFavicon("faviconCustomerPortal.ico"); //method from main.js
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    this.setFavicon("favicon.ico"); //method from main.js
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 900;
    },

    updateUserInfo() {
      if (this.$store.state.token && !this.$store.state.userinfo) {
        this.$store.dispatch("getUserInfo");
      }
    },
  },

  computed: {
    userInfo() {
      if (this.$store.state.userInfo) return this.$store.state.userInfo;
      return { Customer: {}, Provider: {} };
    },
    isLoggedIn() {
      return this.$store.state.token != null;
    },

    pageTitle() {
      if (this.$route.params.Machine?.DisplayName) {
        return this.$route.params.Machine?.DisplayName;
      } else if (this.$route.meta?.displayName) {
        return this.$route.meta.displayName;
      } else {
        return "Partner";
      }
    },
  },

  watch: {},
};
</script>


<style scoped>
.header-class {
  font-size: 1.2em;
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
.sidenav.card {
  border-bottom: 1px solid #e0e0e0;
}
*/
/*
.sidenav.card,
.sidenav.card i.v-icon {
  color: #494949 !important;
}
*/
.sidenav.card.router-link-active::before,
.sidenav.card.router-link-active::after {
  content: "";
  position: absolute;
  z-index: 10;
  height: 100%;
  background-color: var(--v-primary-base) !important;
}

.sidenav.card.router-link-active::before {
  width: 7px;
  opacity: 1;
  border-radius: 2px;
}

.sidenav.card.router-link-active::after {
  width: 100%;
  top: 0;
  opacity: 0.08;
}

.sidenav.card.router-link-active,
.sidenav.card.router-link-active i.v-icon {
  color: var(--v-primary-base) !important;
}
</style>

<style lang="scss">
.borderless-input>>>.v-input__slot::before,
.borderless-input .v-input__control .v-input__slot::before {
  border-style: none !important;
}
</style>