<template>
    <v-card width="100%" class="ma-3">
        <v-row no-gutters>
            <v-col :cols="isPhoneBool ? 12 : 6">
                <h5 v-text="$t('text.company_info')" class="px-3 pt-2" />
                <v-card-text class="py-2">
                    <v-text-field :label="$t('text.name')" readonly hide-details class="borderless-input"
                        v-model="organization.Name">
                    </v-text-field>
                    <v-text-field readonly hide-details class="borderless-input" :label="$t('text.organization_number')"
                        v-model="organization.OrganisationNumber">
                    </v-text-field>
                </v-card-text>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col :cols="isPhoneBool ? 12 : 6">
                <h5 v-text="$t('text.delivery_address')" class="px-3 pt-2" />
                <v-card-text
                    v-if="!!organization.DeliveryAdressStreet || !!organization.DeliveryAdressZip || !!organization.DeliveryAdressCity">
                    {{ organization.DeliveryAdressStreet }} <br>
                    {{ organization.DeliveryAdressZip }} {{ organization.DeliveryAdressCity }} <br>
                    {{ organization.DeliveryAdressCountry }}
                </v-card-text>
                <v-card-text v-else>{{ $t('text.not_specified') }}</v-card-text>
            </v-col>
        </v-row>
    </v-card>
</template>


<script>
export default {
    name: 'OrganizationHeader',
    props: {
        organization: Object,
        loading: Boolean,
    },
    components: {},

    data() {
        return {

        };
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>