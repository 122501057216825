<template>
  <RouterView />
</template>


<script>
export default {
  name: "EmptyRouterView",
  props: {},
  components: {},

  data() {
    return {};
  },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped>
</style>