<template>
  <v-list-item dense :class="hideImage ? '' : 'pl-0'" @click="e => $emit('click', e)">
    <v-list-item-action v-if="showAlerts" class="ma-0 d-flex flex-column justify-center align-start"
      style="min-width: 70px; height: unset;">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-if="item.ActiveServiceJobs" style="border-radius: 4px; width: fit-content;" class="mx-1 px-2"
            x-small color="#8a55f6" dark v-bind="attrs" v-on="on">
            {{ item.ActiveServiceJobs }}
            <v-icon size="14" class="ml-1">mdi-alert</v-icon>
            <v-icon size="14">mdi-briefcase</v-icon>
          </v-chip>
        </template>
        <span>{{ item.ActiveServiceJobs }} open service orders</span>
      </v-tooltip>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-if="item.ActivePartsJobs" style="border-radius: 4px; width: fit-content;" class="mx-1 mt-1 px-2"
            x-small color="#e955f6" dark v-bind="attrs" v-on="on">
            {{ item.ActivePartsJobs }}
            <v-icon size="14" class="ml-1">mdi-alert</v-icon>
            <v-icon size="14">mdi-tag</v-icon>
          </v-chip>
        </template>
        <span>{{ item.ActivePartsJobs }} open part orders</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-icon v-if="!hideImage" class="ma-0 mr-2">
      <MachineModelImage :size="40"
        :relativePath="item && (item.ModelImage || item.MachineModel && item.MachineModel.ModelImage)">
      </MachineModelImage>
    </v-list-item-icon>
    <v-list-item-content class="py-1">
      <v-list-item-title>
        {{ machineGeneralizerText(item) }}
      </v-list-item-title>
      <v-list-item-title class="primary--text">
        {{ machineIdentifierText(item) }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action class="ma-0">
      <v-btn v-if="showOpenBtn" icon @click="openMachine(item)">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>


<script>
import MachineModelImage from "@/components/Cards/MachineModelImage.vue";

export default {
  name: "MachineListItem",
  props: {
    item: Object,
    hideImage: Boolean,
    showAlerts: Boolean,
    showOpenBtn: Boolean,
  },
  components: {
    MachineModelImage,
  },

  data() {
    return {
    };
  },

  mounted() { },

  methods: {
    openMachine(machine) {
      this.$router.push({ name: 'ViewMachine', params: { id: machine.Id } });
    },
  },

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped></style>