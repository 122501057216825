<template>
    <v-card :width="size" :height="size" class="d-flex align-center justify-center" flat color="transparent">

        <v-img v-if="relativePath && !imgLoadError" :height="size" :width="size" contain :src="imgurl()"
            @error="imgLoadError = true">
        </v-img>

        <v-tooltip v-else-if="imgLoadError" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" :size="size * 0.8" color="error lighten-3" v-text="'mdi-image-off'">
                </v-icon>
            </template>
            <span>{{ $t('servicesystem.common_objects.fallbackterms.failed_to_load_image') }}</span>
        </v-tooltip>

        <v-icon v-else :size="size * 0.8" color="disabled" v-text="fallbackIcon">
        </v-icon>

    </v-card>
</template>

<script>
export default {
    name: "QImage2",
    props: {
        size: Number,
        relativePath: String,
        fallbackIcon: String,
    },
    components: {},
    mixins: [],

    data() {
        return {
            imgLoadError: false,
        };
    },

    mounted() {
        //console.log(this.relativePath);
    },

    methods: {
        imgurl() {
            var baseurl = process.env.NODE_ENV === 'production' ? window.location.origin : 'https://test1.qwips.net'; //direct the image source to test1 when in debug
            var rp = this.relativePath;
            if (rp.startsWith('/api2/resource/?relativePath=')) { //if its allready mapped to the resource controller path, return it as is
                return baseurl + this.relativePath;
            }
            else {
                var methodpath = (rp.startsWith('~/') || rp.startsWith('/FileStorage')) ? "/api2/resource/?relativePath=" : "/api2/";
                return baseurl + methodpath + this.relativePath;
            }
        },
    },

    computed: {

    },

    watch: {

    },
};
</script>