<template>
  <v-container fluid>
    <v-alert type="error"
             max-width="800"
             class="mx-auto mt-5"
             prominent>
      <h2>404 Page not found</h2>
      The {{ path }} page was not found. <br>
      This is likely due to, either a manual misspelling in the URL or the host site being one or more updates behind (or ahead). <br>
      If the issue persists, please contact support@qwips.no.
    </v-alert>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      path: this.$route.path,
    };
  },
};
</script>